import ArrowTopLine from "../../Assets/icons/arrow-top-line.png";

const Widget = ({ title, count, nameClass }) => {
  return (
    <section className={`home__widget__item__${nameClass}`}>
      <h3>{title}</h3>
      <div>
        <h2>{count}</h2>
        <img src={ArrowTopLine} alt="" />
      </div>
    </section>
  );
};

export default Widget;
