import { useState, useEffect } from "react";
import Layout from "../../Layout";
import API from "../../Services/API";
import Select from "react-select";
import BeatLoader from "react-spinners/BeatLoader";
import CurrencyInput from "react-currency-input-field";
import { getSelectedYear } from "../../Services/Authentication";

const Create = ({ history }) => {
  const selectedYear = getSelectedYear()
  const [handler, setHandler] = useState({
    isLoading: true,
    items: [],
  });

  const [division, setDivision] = useState({
    isLoading: true,
    items: [],
  });

  const [procurementOfficer, setProcurementOfficer] = useState({
    isLoading: true,
    items: [],
  });

  const [expenseType, setExpenseType] = useState({
    isLoading: true,
    items: [],
  });

  const [fileRequirement, setFileRequirement] = useState({
    isLoading: true,
    items: [],
  });

  const [data, setData] = useState({
    name: null,
    handlerID: null,
    divisionID: null,
    ownerEstimatePrice: null,
    expenseTypeID: null,
    procurementOfficerID: null,
    year: selectedYear
  });

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [fileRequirementChecked, setFileRequirementChecked] = useState([]);

  const [pokjaSelected, setPokjaSelected] = useState({
    ID: null,
    name: null,
  });

  const fetchListHandler = () => {
    API.listHandler().then((response) => {
      if (response.code === 200) {
        setHandler({
          isLoading: false,
          items: response.data.list,
        });
      }
    });
  };

  const fetchListDivisionPure = () => {
    API.listDivision({ pageSize: 100, deleted: 0 }).then((response) => {
      if (response.code === 200) {
        setDivision({
          isLoading: false,
          items: response.data.list,
        });
      }
    });
  };

  const fetchListDivision = () => {
    if (data.procurementOfficerID) {
      API.detailProcurementOfficer(data.procurementOfficerID).then(
        (response) => {
          if (response.code === 200) {
            if (response.data.divisions.length !== 0) {
              setPokjaSelected({
                ID: response.data.divisions[0].ID,
                name: response.data.divisions[0].name,
              });
              setData((prevState) => ({
                ...prevState,
                divisionID: response.data.divisions[0].ID,
              }));
            }
          }
        }
      );
    }
  };

  const fetchListProcurementOfficer = () => {
    API.listProcurementOfficer().then((response) => {
      if (response.code === 200) {
        setProcurementOfficer({
          isLoading: false,
          items: response.data.list,
        });
      }
    });
  };

  const fetchListExpenseType = () => {
    API.listSpending().then((response) => {
      if (response.code === 200) {
        setExpenseType({
          isLoading: false,
          items: response.data.list,
        });
      }
    });
  };

  const fetchListFileRequirement = () => {
    API.listFileRequirement().then((response) => {
      if (response.code === 200) {
        setFileRequirement({
          isLoading: false,
          items: response.data.list,
        });
      }
    });
  };

  useEffect(() => {
    fetchListDivision();
  }, [data.procurementOfficerID]);

  useEffect(() => {
    fetchListExpenseType();
    // fetchListHandler();
    fetchListFileRequirement();
    fetchListProcurementOfficer();
    fetchListDivisionPure();
  }, []);

  const createPengadaan = (event) => {
    event.preventDefault();
    setIsSubmiting(true);

    let assignFile = {};
    let allFileAssigned = fileRequirementChecked;
    for (let i = 0; i < allFileAssigned.length; i++) {
      assignFile = {
        ...assignFile,
        ...{ [`assigns[${i}]`]: allFileAssigned[i] },
      };
    }

    API.createPengadaan(data).then((response) => {
      console.log("Res", response);
      if (response.code === 200) {
        API.assignFile(response.data.ID, assignFile).then((response) => {
          if (response.code === 200) {
            history.push("/project");
          }
        });
      }
      setIsSubmiting(false);
    });
  };

  return (
    <Layout>
      <div className="createPengadaan">
        <h2>Buat Pengadaan Baru</h2>
        <form className="createPengadaan__form" onSubmit={createPengadaan}>
          <section className="createPengadaan__form__left">
            <div>
              <label>Tahun Pengadaan</label>
              <section>
                <input
                  type="text"
                  placeholder="Nama Pengadaan"
                  disabled
                  value={getSelectedYear()}
                />
              </section>
            </div>
            <div>
              <label>Pengadaan</label>
              <section>
                <input
                  type="text"
                  placeholder="Nama Pengadaan"
                  required
                  value={data.name}
                  onChange={({ target: { value } }) =>
                    setData((prevState) => ({ ...prevState, name: value }))
                  }
                />
              </section>
            </div>

            <div>
              <label>Procurement Officer / PP</label>
              <section>
                <Select
                  placeholder="--Pilih PP--"
                  className="selectSearch"
                  onChange={(selectedOption) => {
                    setData((prevState) => ({
                      ...prevState,
                      procurementOfficerID: selectedOption.value,
                    }));
                  }}
                  options={
                    procurementOfficer.isLoading
                      ? [{ label: "Loading" }]
                      : procurementOfficer.items.map((item) => {
                        return { value: item.ID, label: item.name };
                      })
                  }
                />
              </section>
            </div>

            <div>
              <label>Pokja</label>
              <section>
                <Select
                  placeholder="--Pilih Pokja--"
                  className="selectSearch"
                  onChange={(selectedOption) => {
                    setData((prevState) => ({
                      ...prevState,
                      divisionID: selectedOption.value,
                    }));
                    setPokjaSelected({
                      ID: selectedOption.value,
                      name: selectedOption.label,
                    });
                  }}
                  value={
                    pokjaSelected.ID === null
                      ? null
                      : {
                        value: pokjaSelected.ID,
                        label: pokjaSelected.name,
                      }
                  }
                  options={
                    division.isLoading
                      ? [{ label: "Loading" }]
                      : division.items.map((item) => {
                        return { value: item.ID, label: item.name };
                      })
                  }
                />
              </section>
            </div>
          </section>

          <section className="createPengadaan__form__right">
            {/* <div>
              <label>Handler</label>
              <section>
                <Select
                  placeholder="--Pilih Handler--"
                  className="selectSearch"
                  onChange={(selectedOption) => {
                    setData((prevState) => ({
                      ...prevState,
                      handlerID: selectedOption.value,
                    }));
                  }}
                  options={
                    handler.isLoading
                      ? [{ label: "Loading" }]
                      : handler.items.map((item) => {
                          return { value: item.ID, label: item.name };
                        })
                  }
                />
              </section>
            </div> */}
            <div>
              <label>Jenis / Spending</label>
              <section>
                <Select
                  placeholder="--Pilih Spending--"
                  className="selectSearch"
                  onChange={(selectedOption) => {
                    setData((prevState) => ({
                      ...prevState,
                      expenseTypeID: selectedOption.value,
                    }));
                  }}
                  options={
                    expenseType.isLoading
                      ? [{ label: "Loading" }]
                      : expenseType.items.map((item) => {
                        return { value: item.ID, label: item.name };
                      })
                  }
                />
              </section>
            </div>

            <div>
              <label>Budget</label>
              <section>
                <CurrencyInput
                  prefix="Rp "
                  name="input-name"
                  placeholder="Rp.0"
                  required
                  onValueChange={(value, name) =>
                    setData((prevState) => ({
                      ...prevState,
                      ownerEstimatePrice: value,
                    }))
                  }
                />
              </section>
              <section>
                <input
                  disabled
                  type="text"
                  placeholder="Group Pengadaan"
                  value={
                    data.ownerEstimatePrice > 50000000
                      ? "Di Atas 50 Juta"
                      : "Di Bawah 50 Juta"
                  }
                />
              </section>
            </div>

            {/* <div>
              <label>Tanggal Pengajuan</label>
              <section>
                <input type="date" placeholder="Tanggal Pengajuan" />
              </section>
            </div> */}

            <section>
              <button type="submit">
                {isSubmiting ? (
                  <BeatLoader size={6} color="#2962FF" />
                ) : (
                  "Submit"
                )}
              </button>
            </section>
          </section>
        </form>
      </div>

      <div className="createPengadaan" style={{ marginTop: 32 }}>
        <h2>Pilih File Requirement</h2>
        <div className="createPengadaan__file">
          {fileRequirement.isLoading ? (
            <div>
              <label>
                <BeatLoader size={6} color="#2962FF" />
              </label>
              <input type="checkbox" value="file1" />
            </div>
          ) : (
            fileRequirement.items.map((item) => {
              return (
                <div
                  onClick={() => {
                    if (fileRequirementChecked.includes(item.ID)) {
                      const filter = fileRequirementChecked.filter(
                        (file) => file != item.ID
                      );

                      setTimeout(() => {
                        setFileRequirementChecked(filter);
                      }, 500);
                    }
                    setFileRequirementChecked((prevState) => [
                      ...prevState,
                      item.ID,
                    ]);
                  }}
                >
                  <label>{item.name}</label>
                  <input
                    type="checkbox"
                    value={item.ID}
                    checked={fileRequirementChecked.includes(item.ID)}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Create;
