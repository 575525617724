import { useState, useEffect } from "react";

import API from "../../Services/API";
import Layout from "../../Layout";
import BeatLoader from "react-spinners/BeatLoader";

const Update = ({ match, history }) => {
  const vendorID = match.params.id;

  const [data, setData] = useState({
    name: null,
    NPWP: null,
  });

  const [isSubmiting, setIsSubmiting] = useState(false);

  const submit = (event) => {
    event.preventDefault();

    setIsSubmiting(true);

    API.updateVendor(vendorID, data).then((response) => {
      if (response.code === 200) {
        history.push("/rekanan");
      }
      setIsSubmiting(false);
    });
  };

  const fetchDetailVendor = () => {
    API.detailVendor(vendorID).then((response) => {
      if (response.code === 200) {
        setData((prevState) => ({
          ...prevState,
          name: response.data.name,
          NPWP: response.data.NPWP,
        }));
      }
    });
  };

  useEffect(() => {
    fetchDetailVendor();
  }, []);

  return (
    <Layout>
      <div className="formRekanan">
        <h2>Update Rekanan</h2>
        <form onSubmit={submit}>
          <section>
            <h2>No. NPWP</h2>
            <section>
              <div>
                <input
                  type="text"
                  placeholder="NPWP"
                  required
                  required
                  value={data.NPWP}
                  onChange={({ target: { value } }) =>
                    setData((prevState) => ({ ...prevState, NPWP: value }))
                  }
                />
              </div>
              {/* <button type="button">Check</button> */}
            </section>
          </section>
          <section>
            <h2>Nama Rekanan</h2>
            <div>
              <input
                type="text"
                placeholder="Nama Rekanan"
                value={data.name}
                onChange={({ target: { value } }) =>
                  setData((prevState) => ({ ...prevState, name: value }))
                }
                required
              />
            </div>
          </section>
          {/* <section>
            <h2>Alamat</h2>
            <div>
              <textarea rows="10" placeholder="Alamat"></textarea>
            </div>
          </section> */}
          <div>
            <button type="submit">
              {isSubmiting ? <BeatLoader size={6} color="#2962FF" /> : "Update"}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Update;
