import { useState, useEffect } from 'react'
import Layout from '../../../Layout'
import Table from '../../../Components/Table'
import IconCheckbox from '../../../Assets/icons/checkbox_checked.png'
import IconCheckboxChecked from '../../../Assets/icons/checkbox.png'
import IconEdit from '../../../Assets/icons/edit.png'
import { Link } from 'react-router-dom'
import API from '../../../Services/API'

import { Line } from 'rc-progress'
import BeatLoader from 'react-spinners/BeatLoader'
import moment from 'moment'

const Assign = () => {
  const [params, setParams] = useState({
    currentPage: 1,
    pageSize: 20,
    keyword: null,
    projectAssignment: 0,
  })

  const [listPengadaan, setListPengadaan] = useState({
    isLoading: true,
    items: [],
  })

  const [isMore, setIsmore] = useState(false)
  const [pageCount, setPageCount] = useState(1)

  const fetchListPengadaan = () => {
    setListPengadaan((prev) => ({ ...prev, isLoading: true }))
    API.listPengadaan(params).then((response) => {
      if (response.code === 200) {
        setListPengadaan({
          isLoading: false,
          items: response.data.list,
        })
        setIsmore(response.data.pagination.isMore)
        setPageCount(response.data.pagination.pageCount)
      }
    })
  }

  const prevPage = () => {
    if (params.currentPage > 1) {
      setParams((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage - 1,
      }))
    }
  }

  const nextPage = () => {
    if (isMore) {
      setParams((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage + 1,
      }))
    }
  }

  const onSearch = (value) => {
    setParams((prevState) => ({
      ...prevState,
      keyword: value,
    }))
  }

  useEffect(() => {
    fetchListPengadaan()
  }, [params.currentPage, params.keyword])

  const renderPercentageColor = (number) => {
    if (number >= 70) {
      return '#17948C'
    } else if (number >= 30 && number < 70) {
      return '#FB7315'
    } else if (number >= 0 && number < 30) {
      return '#E3544E'
    }
  }

  return (
    <Layout>
      <div className="assign">
        <h2>Table Assign Pengadaan</h2>
        <Table
          isAdd={false}
          isExport={false}
          isFilter={false}
          onSearch={onSearch}
          pagination={{
            currentPage: params.currentPage,
            pageCount: pageCount,
            prevAction: prevPage,
            nextAction: nextPage,
          }}
          table={{
            title: (
              <>
                <td>No. Paket</td>
                <td>ID Unik</td>
                <td>Nama Pengadaan</td>
                <td>Jenis</td>
                <td>Group</td>
                <td>HPS</td>
                <td>PP</td>
                <td>Pokja</td>
                <td>No. Kontrak</td>
                <td>Rekanan</td>
                <td>Handler</td>
                <td>Tgl Pengajuan</td>
                <td>Tgl Kontrak</td>
                <td>File</td>
                <td>Status</td>
                <td>Aksi</td>
              </>
            ),
            item: (
              <>
                {listPengadaan.isLoading ? (
                  <tr className="item">
                    <td colSpan="16" className="emptyState">
                      <BeatLoader size={6} color="#2962FF" />
                    </td>
                  </tr>
                ) : listPengadaan.items.length < 1 ? (
                  <tr className="item">
                    <td colSpan="16" className="emptyState">
                      No Data
                    </td>
                  </tr>
                ) : (
                  listPengadaan.items.map((item, index) => {
                    return (
                      <tr className="item">
                        <td style={{ cursor: 'pointer', textAlign: 'center' }}>{item.orderValue}</td>
                        <td style={{ cursor: 'pointer', textAlign: 'center' }}>{item.ID}</td>
                        <td style={{ cursor: 'pointer' }}>{item.name}</td>

                        <td>{item.expenseType.name}</td>
                        <td style={{ cursor: 'pointer' }}>
                          {item.ownerEstimatePrice > 50000000 ? 'Di Atas 50 Juta' : 'Di Bawah 50 Juta'}
                        </td>
                        <td style={{ cursor: 'pointer' }}>
                          Rp {new Intl.NumberFormat('id').format(item.ownerEstimatePrice)}
                        </td>
                        <td style={{ cursor: 'pointer' }}>
                          {/* {item.handler.name} */}
                          {item.procurementOfficer.name}
                        </td>
                        <td style={{ cursor: 'pointer' }}>
                          {item.division.name}
                          {/* <ul>
                            {item.procurementOfficer?.divisions?.length < 1
                              ? "-"
                              : item.procurementOfficer?.divisions?.map(
                                  (pokja) => {
                                    return <li>{pokja.name}</li>;
                                  }
                                )}
                          </ul> */}
                        </td>
                        <td style={{ cursor: 'pointer' }}>{item.projectContract?.number || '-'}</td>
                        <td style={{ cursor: 'pointer' }}>{item.assignment?.vendor?.name || '-'}</td>
                        <td style={{ cursor: 'pointer' }}>{item.handler?.name || '-'}</td>
                        <td style={{ cursor: 'pointer' }}>
                          {moment(item.created, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')}
                        </td>
                        <td style={{ cursor: 'pointer' }}>
                          {item.projectContract?.date
                            ? moment(item.projectContract?.date, 'YYYY-MM-DD').format('DD-MM-YYYY')
                            : '-'}
                        </td>
                        <td>
                          <Line
                            percent={
                              (item.fileRequirements.filter((file) => file.upload !== null).length /
                                (item.fileRequirements.filter((file) => file.upload !== null).length +
                                  item.fileRequirements.filter((file) => file.upload === null).length)) *
                              100
                            }
                            strokeWidth="22"
                            trailColor="#E0E0E0"
                            trailWidth="22"
                            strokeColor={renderPercentageColor(
                              (item.fileRequirements.filter((file) => file.upload !== null).length /
                                (item.fileRequirements.filter((file) => file.upload !== null).length +
                                  item.fileRequirements.filter((file) => file.upload === null).length)) *
                                100
                            )}
                          />
                          <label style={{ textAlign: 'center' }}>
                            {item.fileRequirements.filter((file) => file.upload !== null).length}/
                            {item.fileRequirements.length}
                          </label>
                        </td>
                        <td>{item.assignment ? 'Assigned' : 'No Assigned'}</td>
                        <td>
                          <div>
                            <Link to={`/project/${item.ID}/assign`}>
                              <img src={IconEdit} title="Assign" />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                )}
              </>
              // <tr className="item">
              //   <td>1</td>
              //   <td>001</td>
              //   <td>Nama Pengadaaan</td>
              //   <td>No assign</td>
              //   <td>No assign</td>
              //   <td>Nurhan</td>
              //   <td>2020-01-19</td>
              //   <td>
              //     <img src={IconCheckboxChecked} />
              //   </td>
              //   <td>
              //     <img src={IconCheckbox} />
              //   </td>
              //   <td>
              //     <div>
              //       <Link to="/project/1/assign/update">
              //         <img src={IconEdit} />
              //       </Link>
              //     </div>
              //   </td>
              // </tr>
            ),
          }}
        />
      </div>
    </Layout>
  )
}

export default Assign
