import { useState, useEffect } from "react";
import Layout from "../../Layout";
import ArrowTopLine from "../../Assets/icons/arrow-top-line.png";
import API from "../../Services/API";
import PieChart from "../../Components/PieChart";
import Widget from "./Widget";
import { spreadYear } from "../../Functions/Spread";

const Home = () => {
  const [project, setProject] = useState({
    underFifty: 0,
    aboveFiftyUnderOneHundred: 0,
    aboveOneHundredUnderTwoHundred: 0,
    aboveTwoHundred: 0,
  });

  const [priceYearStatistic, setPriceYearStatistic] = useState({
    isLoading: true,
    data: {},
  });

  const [listProjectHandler, setListProjectHandler] = useState([]);

  const fetchProject = () => {
    API.listPengadaan({
      price: "le50000000",
    }).then((response) => {
      if (response.code === 200) {
        setProject((prevState) => ({
          ...prevState,
          underFifty: response.data.pagination.itemCount,
        }));
      }
    });

    API.listPengadaan({
      price: "le100000000,gt50000000",
    }).then((response) => {
      if (response.code === 200) {
        setProject((prevState) => ({
          ...prevState,
          aboveFiftyUnderOneHundred: response.data.pagination.itemCount,
        }));
      }
    });

    API.listPengadaan({
      price: "le200000000,gt100000000",
    }).then((response) => {
      if (response.code === 200) {
        setProject((prevState) => ({
          ...prevState,
          aboveOneHundredUnderTwoHundred: response.data.pagination.itemCount,
        }));
      }
    });

    API.listPengadaan({
      price: "gt200000000",
    }).then((response) => {
      if (response.code === 200) {
        setProject((prevState) => ({
          ...prevState,
          aboveTwoHundred: response.data.pagination.itemCount,
        }));
      }
    });

    const accessIDUserLogin =
      localStorage.getItem("procurementOfficerAccessID") || 0;

    API.listPengadaan({
      pageSize: 100,
      procurementOfficerID: accessIDUserLogin,
    }).then((response) => {
      if (response.code === 200) {
        setListProjectHandler(response.data.list);
      }
    });
  };

  const fetchPriceYearStatistic = () => {
    const years = spreadYear(2021)

    API.listPricePerYear({
      year: `le${years[years.length - 1]},ge${years[0]}`,
    }).then((response) => {
      if (response.code === 200) {
        setPriceYearStatistic({
          isLoading: false,
          data: response.data,
        });
      }
    });
  };

  useEffect(() => {
    fetchProject();
    fetchPriceYearStatistic();
  }, []);

  return (
    <Layout>
      <div className="home">
        <div className="home__widget">
          <h2>
            Total Semua Pengadaan :
            {/* <b> {listPengadaan.isLoading ? 0 : listPengadaan.items.length}</b> */}
          </h2>
          <div className="home__widget__item">
            <Widget
              title="< 50 Juta"
              count={project.underFifty}
              nameClass="one"
            />

            <Widget
              title="51 s.d 100 Juta"
              count={project.aboveFiftyUnderOneHundred}
              nameClass="two"
            />

            <Widget
              title="101 s.d 200 Juta"
              count={project.aboveOneHundredUnderTwoHundred}
              nameClass="three"
            />

            <Widget
              title="> 200 Juta"
              count={project.aboveTwoHundred}
              nameClass="four"
            />
          </div>
        </div>

        <div className="home__widget">
          <h2>
            Pengadaan Yang di Handle :<b> {listProjectHandler.length}</b>
          </h2>
        </div>

        <div className="home__chart">
          <h2>
            {/* Grafik Pengadaan Tahun ke Tahun : <b></b> */}
            Grafik Pengadaan Tahun {new Date().getFullYear()} : <b></b>
          </h2>
          <div className="home__chart__item">
            {priceYearStatistic.isLoading
              ? null
              : Object.entries(priceYearStatistic.data).map((value, index) => {
                return (
                  <section>
                    {Object.entries(value[1])[0][1] +
                      Object.entries(value[1])[1][1] +
                      Object.entries(value[1])[2][1] +
                      Object.entries(value[1])[3][1] ===
                      0 ? (
                      <div>
                        <h5>{`Tahun ${value[0]}`}</h5>
                        <p>Data Tidak Ada !</p>
                      </div>
                    ) : (
                      <PieChart
                        title={`Tahun ${value[0]} ( ${Object.entries(value[1])[0][1] +
                          Object.entries(value[1])[1][1] +
                          Object.entries(value[1])[2][1] +
                          Object.entries(value[1])[3][1]
                          } Pengadaan )`}
                        data={[
                          ["< 50 Juta", Object.entries(value[1])[0][1]],
                          [
                            "51 s.d. 100 Juta",
                            Object.entries(value[1])[1][1],
                          ],

                          [
                            "101 s.d. 200 Juta",
                            Object.entries(value[1])[2][1],
                          ],

                          ["> 200 Juta", Object.entries(value[1])[3][1]],
                        ]}
                      />
                    )}
                  </section>
                );
              })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
