import { useState } from "react";

import { ReactComponent as Logo } from "../Assets/logo/mitrasdudi-logo-small.svg";
import IconSetting from "../Assets/icons/settings.png";
import IconFolder from "../Assets/icons/folder.png";
import IconFolderActive from "../Assets/icons/folder-active.png";
import IconUser from "../Assets/icons/user.png";
import IconUserActive from "../Assets/icons/user-active.png";
import IconUserCheck from "../Assets/icons/user_check.png";
import IconUserCheckActive from "../Assets/icons/user_check-active.png";
import IconHome from "../Assets/icons/Union.png";
import IconHomeActive from "../Assets/icons/Union-active.png";
import IconArrowUpRed from "../Assets/icons/chevron_top-red.png";
import IconArrowDownRed from "../Assets/icons/chevron_down-red.png";

import IconArrowTopGrey from "../Assets/icons/chevron_up-grey.png";
import IconArrowDownGrey from "../Assets/icons/chevron_down-grey.png";
import IconClose from "../Assets/icons/close_big.png";
import IconDivision from "../Assets/icons/division.png";
import IconDivisionActive from "../Assets/icons/division_active.png";

import { NavLink, Link, useLocation, useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { logout } from "../Services/Authentication";

const Sidebar = ({ onClose }) => {
  const location = useLocation();
  const history = useHistory();
  const currentPathDatas = location.pathname.split("/");
  const currentPath = location.pathname.split("/")[1];
  const currentSubPath = location.pathname.split("/")[2] || null;
  const [isShowSubmenu, setIsShowSubmenu] = useState(false);

  return (
    <div className="sidebar">
      <div className="sidebar__close">
        <img src={IconClose} alt="" onClick={() => onClose()} />
      </div>
      <div className="sidebar__header">
        <Logo />
        <h2>PROCUREMENT</h2>
      </div>
      <div
        className="sidebar__menu"
        onClick={isMobile ? () => onClose() : null}
      >
        <NavLink
          className="sidebar__menu__link"
          exact
          to="/home"
          activeClassName="--selected"
          isActive={(match, location) => {
            if (location.pathname.includes("/home")) return true;
          }}
        >
          <img
            src={currentPath === "home" ? IconHomeActive : IconHome}
            alt=""
          />
          <span>Home</span>
        </NavLink>

        <NavLink
          className="sidebar__menu__link"
          exact
          to="/project"
          activeClassName="--selected"
          isActive={(match, location) => {
            if (location.pathname.includes("project")) return true;
          }}
        >
          <div>
            <div>
              <img
                src={currentPath === "project" ? IconFolderActive : IconFolder}
                alt=""
              />
              <span>Pengadaan</span>
              <i onClick={() => setIsShowSubmenu(!isShowSubmenu)}>
                {currentPath === "project" ? (
                  <img
                    src={isShowSubmenu ? IconArrowUpRed : IconArrowDownRed}
                    alt=""
                  />
                ) : (
                  <img
                    src={isShowSubmenu ? IconArrowTopGrey : IconArrowDownGrey}
                    alt=""
                  />
                )}
              </i>
            </div>
            {currentPath === "project" ? (
              <Link to="/project">
                <img
                  src={currentSubPath === null ? IconFolderActive : IconFolder}
                  alt=""
                />
                <span
                  style={{
                    color: currentSubPath === null ? "red" : "#959595",
                  }}
                >
                  List
                </span>
              </Link>
            ) : null}

            {currentPath === "project" ? (
              <Link to="/project/assign">
                <img
                  src={
                    currentPathDatas.includes("assign")
                      ? IconFolderActive
                      : IconFolder
                  }
                  alt=""
                />
                <span
                  style={{
                    color: currentPathDatas.includes("assign")
                      ? "red"
                      : "#959595",
                  }}
                >
                  Assign
                </span>
              </Link>
            ) : null}
            {/* 
            {currentPath === "project" ? (
              <Link to="/project/report">
                <img
                  src={
                    currentSubPath === "report" ? IconFolderActive : IconFolder
                  }
                  alt=""
                />
                <span
                  style={{
                    color: currentSubPath === "report" ? "red" : "#959595",
                  }}
                >
                  Report
                </span>
              </Link>
            ) : null} */}
          </div>
        </NavLink>

        {/* <NavLink
          className="sidebar__menu__link"
          exact
          to="/handler"
          activeClassName="--selected"
          isActive={(match, location) => {
            if (location.pathname.includes("/handler")) return true;
          }}
        >
          <img
            src={currentPath === "handler" ? IconUserActive : IconUser}
            alt=""
          />
          <span>Handler ( PIC )</span>
        </NavLink> */}

        <NavLink
          className="sidebar__menu__link"
          exact
          to="/pokja"
          activeClassName="--selected"
          isActive={(match, location) => {
            if (location.pathname.includes("/pokja")) return true;
          }}
        >
          <img
            src={currentPath === "pokja" ? IconDivisionActive : IconDivision}
            alt=""
          />
          <span>Pokja</span>
        </NavLink>

        <NavLink
          className="sidebar__menu__link"
          exact
          to="/rekanan"
          activeClassName="--selected"
          isActive={(match, location) => {
            if (location.pathname.includes("/rekanan")) return true;
          }}
        >
          <img
            src={
              currentPath === "rekanan" ? IconUserCheckActive : IconUserCheck
            }
            alt=""
          />
          <span>Rekanan</span>
        </NavLink>
      </div>
      <div className="sidebar__logout">
        <a onClick={() => logout(history)}>
          <img src={IconSetting} alt="" />
          <span>Logout</span>
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
