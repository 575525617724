import { useState, useEffect } from "react";
import Select from "react-select";

import Layout from "../../../Layout";
import IconCheckbox from "../../../Assets/icons/checkbox.png";
import API from "../../../Services/API";
import BeatLoader from "react-spinners/BeatLoader";
import DownloadIcon from "../../../Assets/icons/cloud_down.png";
import UploadIcon from "../../../Assets/icons/cloud_up.png";
import CurrencyInput from "react-currency-input-field";

const Create = ({ history, match }) => {
  const projectID = match.params.id;
  const [data, setData] = useState({
    // code: null,
    vendorID: null,
    contractPrice: null,
    description: null,
    status: null,
  });

  const [isSubmiting, setIsSubmiting] = useState(false);

  const [vendor, setVendor] = useState({
    isLoading: true,
    items: [],
  });

  const [listHandler, setListHandler] = useState({
    isLoading: true,
    items: [],
  });

  const [contract, setContract] = useState({
    date: null,
    number: null,
  });

  const [fileUpload, setfileUpload] = useState({
    id: null,
    file: null,
  });

  const [projectData, setProjectData] = useState({
    isLoading: true,
    item: null,
  });

  const [handlerSelected, setHandlerSelected] = useState({
    handlerID: null,
  });

  const ID = match.params.id;

  const fetchDetail = () => {
    API.detailPengadaan(ID).then((response) => {
      if (response.code === 200) {
        setProjectData({
          isLoading: false,
          item: response.data,
        });
      }
    });
  };

  const uploadFileRequirement = () => {
    if (fileUpload.id) {
      API.uploadFileRequirement(fileUpload.id, { file: fileUpload.file }).then(
        (response) => {
          if (response.code === 200) {
            setfileUpload({
              id: null,
              file: null,
            });
            fetchDetail();
          }
        }
      );
    }
  };

  const fetchListVendor = () => {
    API.listVendor({ isArchived: 0 }).then((response) => {
      if (response.code === 200) {
        setVendor({
          isLoading: false,
          items: response.data.list,
        });
      }
    });
  };

  const fetchListHandler = () => {
    if (data.vendorID) {
      API.detailVendor(data.vendorID).then((response) => {
        if (response.code === 200) {
          setListHandler({
            isLoading: false,
            items: response.data.handlers,
          });

          if (response.data.handlers.length !== 0) {
            setHandlerSelected({
              handlerID: response.data.handlers[0].ID,
            });
          }
        }
      });
    }
  };

  useEffect(() => {
    fetchListHandler();
  }, [data.vendorID]);

  const submit = (event) => {
    event.preventDefault();

    setIsSubmiting(true);

    API.assignVendor(projectID, data).then((response) => {
      if (response.code === 200) {
        API.assignContract(projectID, contract).then((response) => {
          if (response.code === 200) {
            API.assignPengadaanHandler(projectID, handlerSelected).then(
              (response) => {
                if (response.code === 200) {
                  history.push("/project/assign");
                }
              }
            );
          }
        });
      }
      setIsSubmiting(false);
    });
  };

  useEffect(() => {
    fetchListVendor();
    fetchDetail();
  }, []);

  let fileIDS = [];

  return (
    <Layout>
      <div className="updatePengadaan">
        <h2>Assign Pengadaan</h2>
        <form className="updatePengadaan__form" onSubmit={submit}>
          <section className="updatePengadaan__form__left">
            {/* <div>
              <label>Contract Number ( Code )</label>
              <section>
                <input
                  type="text"
                  placeholder="code"
                  required
                  onChange={({ target: { value } }) =>
                    setData((prevState) => ({
                      ...prevState,
                      code: value,
                    }))
                  }
                />
              </section>
            </div> */}

            <div>
              <label>Rekanan</label>
              <section>
                <Select
                  placeholder="--Pilih Rekanan--"
                  className="selectSearch"
                  onChange={(selectedOption) => {
                    setData((prevState) => ({
                      ...prevState,
                      vendorID: selectedOption.value,
                    }));
                  }}
                  options={
                    vendor.isLoading
                      ? [{ label: "Loading" }]
                      : vendor.items.map((item) => {
                          return { value: item.ID, label: item.name };
                        })
                  }
                />
              </section>
            </div>

            <div>
              <label>Handler</label>
              <section>
                <Select
                  placeholder="--Pilih Pilih--"
                  className="selectSearch"
                  onChange={(selectedOption) => {
                    setHandlerSelected({
                      handlerID: selectedOption.value,
                    });
                  }}
                  value={
                    listHandler.items.length < 1
                      ? null
                      : listHandler.items.map((item) => {
                          if (item.ID === handlerSelected.handlerID) {
                            return {
                              value: item.ID,
                              label: item.name,
                            };
                          }
                        })
                  }
                  options={
                    listHandler.isLoading
                      ? [{ label: "Loading" }]
                      : listHandler.items.map((item) => {
                          return { value: item.ID, label: item.name };
                        })
                  }
                />
              </section>
            </div>

            <div>
              <label>Nilai Kontrak</label>
              <section>
                <CurrencyInput
                  prefix="Rp "
                  name="input-name"
                  placeholder="Rp.0"
                  required
                  onValueChange={(value, name) =>
                    setData((prevState) => ({
                      ...prevState,
                      contractPrice: value,
                    }))
                  }
                />
              </section>
            </div>
          </section>

          <section className="updatePengadaan__form__right">
            <div>
              <label>Deskripsi</label>
              <section>
                <textarea
                  placeholder="Deskripsi"
                  rows="10"
                  required
                  onChange={({ target: { value } }) =>
                    setData((prevState) => ({
                      ...prevState,
                      description: value,
                    }))
                  }
                ></textarea>
              </section>
            </div>

            <div>
              <label>No Kontrak</label>
              <section>
                <input
                  type="text"
                  placeholder="Nomor Kontrak"
                  required
                  onChange={({ target: { value } }) =>
                    setContract((prevState) => ({
                      ...prevState,
                      number: value,
                    }))
                  }
                />
              </section>
            </div>

            <div>
              <label>Tanggal Kontrak</label>
              <section>
                <input
                  type="date"
                  placeholder="Tanggal Kontrak"
                  required
                  onChange={({ target: { value } }) =>
                    setContract((prevState) => ({
                      ...prevState,
                      date: value,
                    }))
                  }
                />
              </section>
            </div>

            {/* <div>
              <label>Status</label>
              <section>
                <select
                  required
                  onChange={({ target: { value } }) =>
                    setData((prevState) => ({
                      ...prevState,
                      status: value,
                    }))
                  }
                >
                  <option>-- Pilih Status --</option>
                  <option value="On-Going">On Going</option>
                </select>
              </section>
            </div> */}

            <section>
              <button type="submit">
                {isSubmiting ? (
                  <BeatLoader size={6} color="#2962FF" />
                ) : (
                  "Submit"
                )}
              </button>
            </section>
          </section>
        </form>
      </div>
      <div className="createPengadaan" style={{ marginTop: 32 }}>
        <h2>File Requirement</h2>

        {/* <div className="createPengadaan__file">
          <aside className="createPengadaan__file__kontrak">
            <label>No Kontrak</label>
            <section>
              <input type="text" placeholder="No Kontrak" />
            </section>
          </aside>
          <aside className="createPengadaan__file__kontrak">
            <label>Tanggal Kontrak</label>
            <section>
              <input type="date" placeholder="Tanggal Kontrak" />
            </section>
          </aside>
          <section>
            <button>Submit</button>
          </section>
        </div> */}

        <div className="createPengadaan__file">
          <table>
            <tr>
              <td>Upload</td>
              <td>Nama File</td>
              <td>Status</td>
              <td>Aksi</td>
            </tr>
            {projectData.isLoading ? (
              <tr>
                <td colSpan="4">
                  <BeatLoader size={6} color="#2962FF" />
                </td>
              </tr>
            ) : projectData.item.fileRequirements.length < 1 ? (
              <tr>
                <td colSpan="4" style={{ textAlign: "center", padding: 20 }}>
                  Tidak Ada File Requirement
                </td>
              </tr>
            ) : (
              projectData.item.fileRequirements.map((item, index) => {
                fileIDS.push(item.ID);
                return (
                  <tr>
                    <td>
                      <div
                        id="dropContainer"
                        style={{
                          width: "100%",
                          height: 50,
                          border: "1px solid #eeeeee",
                          borderStyle: "dashed",
                          backgroundColor: "#fafafa",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: 10,
                        }}
                        onDragOver={(event) => {
                          event.preventDefault();
                        }}
                        onDrop={(event) => {
                          let fileInput = document.getElementById(
                            `fileInput${item.ID}`
                          );

                          // pretty simple -- but not for IE :(
                          fileInput.files = event.dataTransfer.files;

                          const dT = new DataTransfer();
                          dT.items.add(event.dataTransfer.files[0]);
                          fileInput.files = dT.files;

                          setfileUpload({
                            id: item.ID,
                            file: event.dataTransfer.files[0],
                          });

                          event.preventDefault();
                        }}
                      >
                        Drop File Here
                      </div>
                      <input
                        id={`fileInput${item.ID}`}
                        type="file"
                        onChange={({ target: { files } }) => {
                          setfileUpload({
                            id: item.ID,
                            file: files[0],
                          });
                        }}
                      />
                    </td>
                    <td>{item.requirement.name}</td>
                    <td
                      style={{ color: item.upload === null ? "red" : "green" }}
                    >
                      {item.upload === null
                        ? "Belum Di Upload"
                        : "Sudah Di Upload"}
                    </td>
                    <td>
                      <img
                        src={UploadIcon}
                        title="Upload File"
                        style={{ marginRight: 10 }}
                        onClick={() => uploadFileRequirement()}
                      />
                      {item.upload ? (
                        <a
                          href={item.upload.url}
                          target="_blank"
                          rel="noopener"
                        >
                          <img src={DownloadIcon} title="Download File" />
                        </a>
                      ) : null}
                    </td>
                  </tr>
                );
              })
            )}
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Create;
