import Modal from "react-modal";

const ModalCreate = ({ children, isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      className="modalContainer"
      overlayClassName="modalOverlayCenter"
      shouldCloseOnOverlayClick={false}
    >
      {children}
    </Modal>
  );
};

export default ModalCreate;
