import API from "./API";

const STORAGE_KEY = "token";

export const getToken = () => {
  return localStorage.getItem(STORAGE_KEY);
};

export const getSelectedYear = () => {
  return localStorage.getItem('year');
};

export const login = (username, password, year) => {
  return API.login(username, password).then((response) => {
    console.log("Response Login auth", response);
    if (response.data?.token || response.code === 200) {
      localStorage.setItem(STORAGE_KEY, response.data.token);
      localStorage.setItem("email", response.data.account.email);
      localStorage.setItem(
        "procurementOfficerAccessID",
        response.data.account.procurementOfficerAccess.ID
      );
      localStorage.setItem("year", year);
    } else {
      return Promise.resolve(response);
    }
    return Promise.resolve(response);
  });
};

export const isLoggedIn = () => {
  const token = localStorage.getItem(STORAGE_KEY);
  if (token) {
    return true;
  } else {
    return false;
  }
};

export const logout = (history) => {
  localStorage.removeItem(STORAGE_KEY);
  localStorage.removeItem("email");
  localStorage.removeItem("role");
  localStorage.removeItem("year");
  history.push("/login");
};
