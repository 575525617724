import { useState, useEffect } from 'react'
import Layout from '../../Layout'
import IconCheckbox from '../../Assets/icons/checkbox.png'
import API from '../../Services/API'
import Select from 'react-select'
import BeatLoader from 'react-spinners/BeatLoader'
import CurrencyInput from 'react-currency-input-field'

const Update = ({ match, history }) => {
  const [handler, setHandler] = useState({
    isLoading: true,
    items: [],
  })

  const [division, setDivision] = useState({
    isLoading: true,
    items: [],
  })

  const [procurementOfficer, setProcurementOfficer] = useState({
    isLoading: true,
    items: [],
  })

  const [isLoadingDetail, setIsLoadingDetail] = useState(true)
  const [data, setData] = useState({
    name: null,
    handlerID: null,
    ownerEstimatePrice: null,
    expenseTypeID: null,
    procurementOfficerID: null,
    divisionID: null,
  })

  const [isSubmiting, setIsSubmiting] = useState(false)

  const ID = match.params.id

  const fetchDetail = () => {
    API.detailPengadaan(ID).then((response) => {
      if (response.code === 200) {
        setIsLoadingDetail(false)
        setData({
          name: response.data.name,
          divisionID: response.data.division?.ID,
          ownerEstimatePrice: response.data.ownerEstimatePrice,
          expenseTypeID: response.data.expenseType.ID,
          procurementOfficerID: response.data.procurementOfficer.ID,
        })
      }
    })
  }

  const fetchListHandler = () => {
    API.listHandler().then((response) => {
      if (response.code === 200) {
        setHandler({
          isLoading: false,
          items: response.data.list,
        })
      }
    })
  }

  const fetchListDivisionPure = () => {
    API.listDivision({ pageSize: 1000 }).then((response) => {
      if (response.code === 200) {
        setDivision({
          isLoading: false,
          items: response.data.list,
        })
      }
    })
  }

  // const fetchListDivision = () => {
  //   if (data.procurementOfficerID) {
  //     API.detailProcurementOfficer(data.procurementOfficerID).then(
  //       (response) => {
  //         if (response.code === 200) {
  //           setDivision({
  //             isLoading: false,
  //             items: [...response.data.divisions, ...division.items],
  //           });
  //         }
  //       }
  //     );
  //   }
  // };

  const fetchListProcurementOfficer = () => {
    API.listProcurementOfficer().then((response) => {
      if (response.code === 200) {
        setProcurementOfficer({
          isLoading: false,
          items: response.data.list,
        })
      }
    })
  }

  // useEffect(() => {
  //   fetchListDivision();
  // }, [data.procurementOfficerID]);

  useEffect(() => {
    fetchDetail()
    fetchListHandler()
    fetchListProcurementOfficer()
    fetchListDivisionPure()
  }, [])

  const updatePengadaan = (event) => {
    event.preventDefault()

    setIsSubmiting(true)

    API.updatePengadaan(ID, data).then((response) => {
      console.log('Res', response)
      if (response.code === 200) {
        history.push('/project')
      }
      setIsSubmiting(false)
    })
  }

  return (
    <Layout>
      <div className="updatePengadaan">
        <h2>Update Pengadaan (ID Unik : {ID})</h2>
        <form className="updatePengadaan__form" onSubmit={updatePengadaan}>
          <section className="updatePengadaan__form__left">
            <div>
              <label>Pengadaan</label>
              <section>
                <input
                  type="text"
                  placeholder="Nama Pengadaan"
                  required
                  value={data.name}
                  onChange={({ target: { value } }) => setData((prevState) => ({ ...prevState, name: value }))}
                />
              </section>
            </div>

            <div>
              <label>Procurement Officer / PP</label>
              <section>
                <Select
                  value={
                    procurementOfficer.isLoading
                      ? { label: 'Loading', value: null }
                      : procurementOfficer.items.map((item) => {
                          if (data.procurementOfficerID === item.ID) return { value: item.ID, label: item.name }
                        })
                  }
                  placeholder="--Pilih PP--"
                  className="selectSearch"
                  onChange={(selectedOption) => {
                    setData((prevState) => ({
                      ...prevState,
                      procurementOfficerID: selectedOption.value,
                    }))
                  }}
                  options={
                    procurementOfficer.isLoading
                      ? [{ label: 'Loading' }]
                      : procurementOfficer.items.map((item) => {
                          return { value: item.ID, label: item.name }
                        })
                  }
                />
              </section>
            </div>

            <div>
              <label>Pokja</label>
              <section>
                <Select
                  // value={
                  //   division.items.length < 1
                  //     ? null
                  //     : {
                  //         value: division.items[0].ID,
                  //         label: division.items[0].name,
                  //       }
                  // }
                  value={
                    division.isLoading
                      ? null
                      : division.items.map((divisi) => {
                          if (divisi.ID === data.divisionID) {
                            return { value: divisi.ID, label: divisi.name }
                          } else {
                            return null
                          }
                        })
                  }
                  placeholder="--Pilih Pokja--"
                  className="selectSearch"
                  onChange={(selectedOption) => {
                    setData((prevState) => ({
                      ...prevState,
                      divisionID: selectedOption.value,
                    }))
                  }}
                  options={
                    division.isLoading
                      ? [{ label: 'Loading' }]
                      : division.items.map((item) => {
                          return { value: item.ID, label: item.name }
                        })
                  }
                />
              </section>
            </div>
          </section>

          <section className="updatePengadaan__form__right">
            <div>
              <label>Jenis / Spending</label>
              <section>
                <Select
                  value={
                    data.expenseTypeID === 1
                      ? { value: 1, label: 'Belanja Barang' }
                      : data.expenseTypeID === 2
                      ? { value: 2, label: 'Belanja Modal' }
                      : { value: 3, label: 'konsultan jasa lainnya' }
                  }
                  placeholder="--Pilih Divisi--"
                  className="selectSearch"
                  onChange={(selectedOption) => {
                    setData((prevState) => ({
                      ...prevState,
                      expenseTypeID: selectedOption.value,
                    }))
                  }}
                  options={[
                    { value: 1, label: 'Belanja Barang' },
                    { value: 2, label: 'Belanja Modal' },
                    { value: 3, label: 'konsultan jasa lainnya' },
                  ]}
                />
              </section>
            </div>

            <div>
              <label>Budget</label>
              <section>
                <CurrencyInput
                  prefix="Rp "
                  name="input-name"
                  placeholder="Rp.0"
                  value={data.ownerEstimatePrice}
                  required
                  onValueChange={(value, name) =>
                    setData((prevState) => ({
                      ...prevState,
                      ownerEstimatePrice: value,
                    }))
                  }
                />
              </section>
              <section>
                <input
                  disabled
                  type="text"
                  placeholder="Group Pengadaan"
                  value={data.ownerEstimatePrice > 50000000 ? 'Di Atas 50 Juta' : 'Di Bawah 50 Juta'}
                />
              </section>
            </div>

            {/* <div>
              <label>Critical Update</label>
              <section>
                <select>
                  <option>Pilih PIC</option>
                </select>
              </section>
              <section>
                <textarea placeholder="Alasan" rows="10"></textarea>
              </section>
            </div> */}

            <section>
              <button type="submit">{isSubmiting ? <BeatLoader size={6} color="#2962FF" /> : 'Update'}</button>
            </section>
          </section>
        </form>
      </div>
    </Layout>
  )
}

export default Update
