import { useState, useEffect } from 'react'
import Layout from '../../Layout'
import Table from '../../Components/Table'
import IconEdit from '../../Assets/icons/edit.png'
import IconTrash from '../../Assets/icons/trash_empty.png'
import IconRefresh from '../../Assets/icons/refresh.png'
import IconUserPlus from '../../Assets/icons/user_plus.png'
import { Link } from 'react-router-dom'
import API from '../../Services/API'
import ModalCreate from '../../Components/ModalCreate'
import BeatLoader from 'react-spinners/BeatLoader'
import Select from 'react-select'

const Division = () => {
  const [isShowModalCreateDivision, setIsShowModalCreateDivision] = useState(false)

  const [isShowModalUpdateDivision, setIsShowModalUpdateDivision] = useState(false)

  const [isShowModalAssignDivision, setIsShowModalAssignDivision] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')

  const [params, setParams] = useState({
    currentPage: 1,
    pageSize: 20,
    keyword: null,
    deleted: 0,
    expand: 'procurementOfficers',
  })

  const [division, setDivision] = useState({
    isLoading: true,
    items: [],
  })

  const [listDivision, setlistDivision] = useState({
    isLoading: true,
    items: [],
  })

  const [data, setData] = useState({
    ID: null,
    name: null,
  })

  const [pp, setPP] = useState({
    isLoading: true,
    items: [],
  })

  const [isModalDeleteShow, setIsModalDeleteShow] = useState(false)
  const [selectedItemDelete, setselectedItemDelete] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)

  const [isSubmiting, setIsSubmiting] = useState(false)
  const [ppSeleted, setPPSeleted] = useState()

  const [isMore, setIsmore] = useState(false)
  const [pageCount, setPageCount] = useState(1)

  const [ppSeletedData, setPpSeletedData] = useState([])
  const [ppDeletedData, setPpDeletedData] = useState([])

  const [idDivisionSelect, setIdDivisionSelect] = useState(null)

  const fetchlistPP = () => {
    API.listProcurementOfficer().then((response) => {
      if (response.code === 200) {
        setPP({
          isLoading: false,
          items: response.data.list,
        })
      }
    })
  }

  const fetchListDivision = () => {
    API.listDivision(params).then((response) => {
      if (response.code === 200) {
        setlistDivision({
          isLoading: false,
          items: response.data.list,
        })
        setIsmore(response.data.pagination.isMore)
        setPageCount(response.data.pagination.pageCount)
      }
    })
  }

  const prevPage = () => {
    if (params.currentPage > 1) {
      setParams((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage - 1,
      }))
    }
  }

  const nextPage = () => {
    if (isMore) {
      setParams((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage + 1,
      }))
    }
  }

  const onSearch = (value) => {
    setParams((prevState) => ({
      ...prevState,
      keyword: value,
    }))
  }

  useEffect(() => {
    fetchlistPP()
  }, [])

  useEffect(() => {
    fetchListDivision()
  }, [params.currentPage, params.keyword, params.deleted])

  const closeModalUpdate = () => {
    setIsShowModalUpdateDivision(false)
  }

  const closeModalAssign = () => {
    setIsShowModalAssignDivision(false)
  }

  const closeModal = () => {
    setIsShowModalCreateDivision(false)
  }

  const closeModalDelete = () => {
    setIsModalDeleteShow(false)
    setselectedItemDelete(null)
  }

  const deletePokja = () => {
    setIsDeleting(true)

    API.archiveDivision(selectedItemDelete.id, { deleted: 1 }).then((response) => {
      if (response.code === 200) {
        setIsDeleting(false)
        setIsModalDeleteShow(false)
        setselectedItemDelete(null)
        fetchListDivision()
      }
    })
  }

  const rollbackDivision = (id) => {
    API.archiveDivision(id, { deleted: 0 }).then((response) => {
      if (response.code === 200) {
        fetchListDivision()
      }
    })
  }

  const openModal = () => {
    setIsShowModalCreateDivision(true)
  }

  const fetchDetailDivision = (id) => {
    API.detailDivision(id).then((response) => {
      if (response.code === 200) {
        setData((prevState) => ({
          ...prevPage,
          name: response.data.name,
          ID: response.data.ID,
        }))
        setPpSeletedData(response.data.procurementOfficers)
      }
    })
  }

  const createDivision = () => {
    setIsSubmiting(true)
    API.createDivision({
      name: data.name,
    }).then((response) => {
      if (response.code === 200) {
        fetchListDivision()
        setIsShowModalCreateDivision(false)
        setErrorMessage('')
      } else {
        setErrorMessage('Nama Tidak Boleh Melebihi 50 Karakter !')
      }
      setIsSubmiting(false)
    })
  }

  const updateDivision = () => {
    setIsSubmiting(true)

    API.updateDivision(data.ID, {
      name: data.name,
    }).then((response) => {
      if (response.code === 200) {
        fetchListDivision()
        setIsShowModalUpdateDivision(false)
        setErrorMessage('')
      } else {
        setErrorMessage('Nama Tidak Boleh Melebihi 50 Karakter !')
      }
      setIsSubmiting(false)
    })
  }

  const removePP = (ppSeletedID) => {
    let assignPP = {}
    assignPP = {
      ...assignPP,
      ...{ [`removes[0]`]: data.ID },
    }

    API.assignPokjaPP(ppSeletedID, assignPP).then((response) => {
      if (response.code === 200) {
        fetchDetailDivision(idDivisionSelect)
        fetchListDivision()
      }
    })
  }

  const assignDivision = () => {
    setIsSubmiting(true)

    let assignPP = {}
    assignPP = {
      ...assignPP,
      ...{ [`assigns[0]`]: data.ID },
    }

    API.assignPokjaPP(ppSeleted, assignPP).then((response) => {
      if (response.code === 200) {
        fetchListDivision()
      }
      setIsSubmiting(false)
      setIsShowModalAssignDivision(false)
    })
  }

  const openModalUpdate = (id) => {
    setIdDivisionSelect(id)

    setData({
      ID: null,
      defaultDivision: null,
      name: 'Loading...',
    })
    fetchDetailDivision(id)
    setIsShowModalUpdateDivision(true)
  }

  const openModalAssign = (id) => {
    setData({
      ID: null,
      defaultDivision: null,
      name: 'Loading...',
    })
    fetchDetailDivision(id)
    setIsShowModalAssignDivision(true)
  }

  const filter = (value) => {
    setParams((prevState) => ({ ...prevState, deleted: value }))
  }

  return (
    <Layout>
      <div className="division">
        <h2>Table List Pokja </h2>
        <Table
          isModal={true}
          isModalAction={openModal}
          buttonAddName="Pokja"
          isExport={false}
          isFilter={true}
          filterAction={filter}
          pagination={{
            currentPage: params.currentPage,
            pageCount: pageCount,
            prevAction: prevPage,
            nextAction: nextPage,
          }}
          onSearch={onSearch}
          table={{
            title: (
              <>
                <td>No.</td>
                <td>Nama Pokja</td>
                <td>Procurement Officer / PP</td>
                <td>Aksi</td>
              </>
            ),
            item: (
              <>
                {listDivision.isLoading ? (
                  <tr className="item">
                    <td colSpan="4" className="emptyState">
                      <BeatLoader size={6} color="#2962FF" />
                    </td>
                  </tr>
                ) : listDivision.items.length < 1 ? (
                  <tr className="item">
                    <td colSpan="4" className="emptyState">
                      No Data
                    </td>
                  </tr>
                ) : (
                  listDivision.items.map((item, index) => {
                    return (
                      <tr className="item">
                        <td style={{ textAlign: 'center' }}>
                          {params.currentPage * params.pageSize + (index - params.pageSize + 1)}
                        </td>
                        <td>{item.name}</td>
                        <td>
                          <ul>
                            {item.procurementOfficers.length < 1
                              ? '-'
                              : item.procurementOfficers.map((officer) => {
                                  return <li>{officer.name}</li>
                                })}
                          </ul>
                        </td>
                        <td>
                          <div>
                            <img src={IconEdit} onClick={() => openModalUpdate(item.ID)} title="Edit" />
                            <img src={IconUserPlus} onClick={() => openModalAssign(item.ID)} />
                            {params.deleted == 1 ? (
                              <img src={IconRefresh} title="Rollback" onClick={() => rollbackDivision(item.ID)} />
                            ) : (
                              <img
                                src={IconTrash}
                                title="Delete"
                                onClick={() => {
                                  setselectedItemDelete({
                                    id: item.ID,
                                    name: item.name,
                                  })
                                  setIsModalDeleteShow(true)
                                }}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })
                )}
              </>
            ),
          }}
        />
      </div>
      <ModalCreate isOpen={isShowModalCreateDivision} onRequestClose={closeModal}>
        <div className="formModal">
          <h2>Tambah Pokja</h2>
          <aside>
            <h3>Nama</h3>
            <section>
              <input
                type="text"
                placeholder="Nama Pokja"
                onChange={({ target: { value } }) =>
                  setData((prevState) => ({
                    ...prevState,
                    name: value,
                  }))
                }
              />
            </section>
          </aside>
          <p style={{ color: 'red', fontSize: 10, marginTop: 10 }}>{errorMessage}</p>
          <div>
            <button
              onClick={() => {
                setErrorMessage('')
                setIsShowModalCreateDivision(false)
              }}
            >
              Cancel
            </button>
            <button disabled={data.name === null} onClick={() => createDivision()}>
              {isSubmiting ? <BeatLoader size={6} color="#2962FF" /> : 'Simpan'}
            </button>
          </div>
        </div>
      </ModalCreate>

      {/* Update */}
      <ModalCreate isOpen={isShowModalUpdateDivision} onRequestClose={closeModalUpdate}>
        <div className="formModal">
          <h2>Update Pokja</h2>
          <aside>
            <h3>Nama</h3>
            <section>
              <input
                type="text"
                placeholder="Nama Pokja"
                value={data.name}
                onChange={({ target: { value } }) =>
                  setData((prevState) => ({
                    ...prevState,
                    name: value,
                  }))
                }
              />
            </section>
          </aside>
          <p style={{ color: 'red', fontSize: 10, marginTop: 10 }}>{errorMessage}</p>
          <aside>
            {ppSeletedData.map((ppItem) => {
              return (
                <section style={{ display: 'flex', alignItems: 'center' }}>
                  <input type="text" value={ppItem.name} disabled />
                  <a style={{ fontSize: 12, color: 'red', cursor: 'pointer' }} onClick={() => removePP(ppItem.ID)}>
                    Delete
                  </a>
                </section>
              )
            })}
          </aside>
          <div>
            <button
              onClick={() => {
                setErrorMessage('')
                setIsShowModalUpdateDivision(false)
              }}
            >
              Cancel
            </button>
            <button onClick={() => updateDivision()} disabled={data.ID === null}>
              {isSubmiting ? <BeatLoader size={6} color="#2962FF" /> : 'Simpan'}
            </button>
          </div>
        </div>
      </ModalCreate>

      {/* Assign */}
      <ModalCreate isOpen={isShowModalAssignDivision} onRequestClose={closeModalAssign}>
        <div className="formModal">
          <h2>Assign PP</h2>
          <aside>
            <h3>Pilih PP</h3>
            <section>
              <Select
                placeholder="--Pilih PP--"
                className="selectSearch"
                onChange={(selectedOption) => setPPSeleted(selectedOption.value)}
                options={
                  pp.isLoading
                    ? [{ label: 'Loading' }]
                    : pp.items.map((item) => {
                        return { value: item.ID, label: item.name }
                      })
                }
              />
            </section>
          </aside>
          <div>
            <button onClick={() => setIsShowModalAssignDivision(false)}>Cancel</button>
            <button onClick={() => assignDivision()} disabled={ppSeleted === null}>
              {isSubmiting ? <BeatLoader size={6} color="#2962FF" /> : 'Simpan'}
            </button>
          </div>
        </div>
      </ModalCreate>

      {/* Delete*/}
      <ModalCreate isOpen={isModalDeleteShow && selectedItemDelete !== null} onRequestClose={closeModalDelete}>
        <div className="formModal">
          <h2 style={{ fontSize: 14 }}>
            Yakin ingin menghapus <b>{selectedItemDelete?.name} </b>?
          </h2>
          <div>
            <button onClick={closeModalDelete}>Cancel</button>
            <button onClick={() => deletePokja()}>{isDeleting ? 'Menghapus...' : 'Ya, Hapus'} </button>
          </div>
        </div>
      </ModalCreate>
    </Layout>
  )
}

export default Division
