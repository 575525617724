

/**
 * Spread array with default value
 * @param {*} size 
 * @param {*} defaultValue 
 * @returns 
 */
export const spreadList = (size, defaultValue = 0) => Array(size).fill(defaultValue)

/**
 * Spread array with each element consist of year increment from 'from' to 'to'
 * @param {*} from 
 * @param {*} to 
 * @returns 
 */
export const spreadYear = (from, to = new Date().getFullYear()) => {
    if (isNaN(from) || isNaN(to) || from > to) {
        throw new Error('It must be integer format and make sure from cannot be greater than to')
    }
    return spreadList((to - from) + 1).map((_, index) => from + index)
}

