import { useState, useEffect } from 'react'
import Layout from '../../Layout'
import Table from '../../Components/Table'
import IconCheckbox from '../../Assets/icons/checkbox_checked.png'
import IconCheckboxChecked from '../../Assets/icons/checkbox.png'
import IconEdit from '../../Assets/icons/edit.png'
import IconTrash from '../../Assets/icons/trash_empty.png'
import IconRefresh from '../../Assets/icons/refresh.png'
import { Link } from 'react-router-dom'
import API from '../../Services/API'

import ModalCreate from '../../Components/ModalCreate'

import { Line } from 'rc-progress'
import BeatLoader from 'react-spinners/BeatLoader'
import moment from 'moment'
import { getSelectedYear } from '../../Services/Authentication'

const Pengadaan = ({ history }) => {
  const selectedYear = getSelectedYear()
  const [params, setParams] = useState({
    currentPage: 1,
    pageSize: 20,
    keyword: null,
    deleted: 0,
    year: `eq${selectedYear}`
  })

  const [listPengadaan, setListPengadaan] = useState({
    isLoading: true,
    items: [],
  })

  const [isMore, setIsmore] = useState(false)
  const [pageCount, setPageCount] = useState(1)

  const [isModalDeleteShow, setIsModalDeleteShow] = useState(false)
  const [selectedItemDelete, setselectedItemDelete] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)

  const closeModalDelete = () => {
    setIsModalDeleteShow(false)
    setselectedItemDelete(null)
  }

  const deleteProject = () => {
    setIsDeleting(true)

    API.archivePengadaan(selectedItemDelete.id, { deleted: 1 }).then((response) => {
      if (response.code === 200) {
        setIsDeleting(false)
        setIsModalDeleteShow(false)
        setselectedItemDelete(null)
        fetchListPengadaan()
      }
    })
  }

  const rollbackProject = (id) => {
    API.archivePengadaan(id, { deleted: 0 }).then((response) => {
      if (response.code === 200) {
        setIsModalDeleteShow(false)
        setselectedItemDelete(null)
        fetchListPengadaan()
      }
    })
  }

  const fetchListPengadaan = () => {
    setListPengadaan((prev) => ({ ...prev, isLoading: true }))
    API.listPengadaan(params).then((response) => {
      if (response.code === 200) {
        setListPengadaan({
          isLoading: false,
          items: response.data.list,
        })
        setIsmore(response.data.pagination.isMore)
        setPageCount(response.data.pagination.pageCount)
      }
    })
  }

  const prevPage = () => {
    if (params.currentPage > 1) {
      setParams((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage - 1,
      }))
    }
  }

  const nextPage = () => {
    if (isMore) {
      setParams((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage + 1,
      }))
    }
  }

  const onSearch = (value) => {
    setParams((prevState) => ({
      ...prevState,
      keyword: value,
    }))
  }

  useEffect(() => {
    fetchListPengadaan()
  }, [params.currentPage, params.keyword, params.deleted])

  const renderPercentageColor = (number) => {
    if (number >= 70) {
      return '#17948C'
    } else if (number >= 30 && number < 70) {
      return '#FB7315'
    } else if (number >= 0 && number < 30) {
      return '#E3544E'
    }
  }

  const filter = (value) => {
    setParams((prevState) => ({ ...prevState, deleted: value }))
  }

  return (
    <Layout>
      <div className="pengadaan">
        <h2>Table List Pengadaan</h2>
        <Table
          actionAdd="/project/create"
          buttonAddName="Pengadaan"
          pagination={{
            currentPage: params.currentPage,
            pageCount: pageCount,
            prevAction: prevPage,
            nextAction: nextPage,
          }}
          isFilter={true}
          filterAction={filter}
          onSearch={onSearch}
          table={{
            title: (
              <>
                <td>No. Paket</td>
                <td>ID Unik</td>
                <td>Nama Pengadaan</td>
                <td>Jenis</td>
                <td>Group</td>
                <td>HPS</td>
                <td>PP</td>
                <td>Pokja</td>
                <td>No. Kontrak</td>
                <td>Nilai Kontrak</td>
                <td>Rekanan</td>
                <td>Handler</td>
                <td>Tgl Pengajuan</td>
                <td>Tgl Kontrak</td>
                <td>File</td>
                <td>Status</td>
                <td>Aksi</td>
              </>
            ),
            item: (
              <>
                {listPengadaan.isLoading ? (
                  <tr className="item">
                    <td colSpan="16" className="emptyState">
                      <BeatLoader size={6} color="#2962FF" />
                    </td>
                  </tr>
                ) : listPengadaan.items.length < 1 ? (
                  <tr className="item">
                    <td colSpan="16" className="emptyState">
                      No Data
                    </td>
                  </tr>
                ) : (
                  listPengadaan.items.map((item, index) => {
                    return (
                      <tr className="item">
                        <td
                          style={{ cursor: 'pointer', textAlign: 'center' }}
                          onClick={() => history.push(`/project/${item.ID}/detail`)}
                        >
                          {item.orderValue}
                        </td>
                        <td
                          style={{ cursor: 'pointer', textAlign: 'center' }}
                          onClick={() => history.push(`/project/${item.ID}/detail`)}
                        >
                          {item.ID}
                        </td>
                        <td style={{ cursor: 'pointer' }} onClick={() => history.push(`/project/${item.ID}/detail`)}>
                          {item.name}
                        </td>
                        <td style={{ cursor: 'pointer' }} onClick={() => history.push(`/project/${item.ID}/detail`)}>
                          {item.expenseType.name}
                        </td>
                        <td style={{ cursor: 'pointer' }} onClick={() => history.push(`/project/${item.ID}/detail`)}>
                          {item.ownerEstimatePrice > 50000000 ? 'Di Atas 50 Juta' : 'Di Bawah 50 Juta'}
                        </td>
                        <td style={{ cursor: 'pointer' }} onClick={() => history.push(`/project/${item.ID}/detail`)}>
                          Rp {new Intl.NumberFormat('id').format(item.ownerEstimatePrice)}
                        </td>
                        <td style={{ cursor: 'pointer' }} onClick={() => history.push(`/project/${item.ID}/detail`)}>
                          {item.procurementOfficer.name}
                        </td>
                        <td style={{ cursor: 'pointer' }} onClick={() => history.push(`/project/${item.ID}/detail`)}>
                          {item.division.name}
                          {/* <ul>
                            {item.procurementOfficer?.divisions?.length < 1
                              ? "-"
                              : item.procurementOfficer?.divisions?.map(
                                  (pokja) => {
                                    return <li>{pokja.name}</li>;
                                  }
                                )}
                          </ul> */}
                        </td>
                        <td style={{ cursor: 'pointer' }} onClick={() => history.push(`/project/${item.ID}/detail`)}>
                          {item.projectContract?.number || '-'}
                        </td>
                        <td style={{ cursor: 'pointer' }} onClick={() => history.push(`/project/${item.ID}/detail`)}>
                          {item.assignment !== null
                            ? `Rp
                          ${new Intl.NumberFormat('id').format(item.assignment?.contractPrice)}`
                            : '-'}
                        </td>
                        <td style={{ cursor: 'pointer' }} onClick={() => history.push(`/project/${item.ID}/detail`)}>
                          {item.assignment?.vendor?.name || '-'}
                        </td>
                        <td style={{ cursor: 'pointer' }} onClick={() => history.push(`/project/${item.ID}/detail`)}>
                          {item.handler?.name || '-'}
                        </td>
                        <td style={{ cursor: 'pointer' }} onClick={() => history.push(`/project/${item.ID}/detail`)}>
                          {moment(item.created, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')}
                        </td>
                        <td style={{ cursor: 'pointer' }} onClick={() => history.push(`/project/${item.ID}/detail`)}>
                          {item.projectContract?.date
                            ? moment(item.projectContract?.date, 'YYYY-MM-DD').format('DD-MM-YYYY')
                            : '-'}
                        </td>
                        <td>
                          <Line
                            percent={
                              (item.fileRequirements.filter((file) => file.upload !== null).length /
                                (item.fileRequirements.filter((file) => file.upload !== null).length +
                                  item.fileRequirements.filter((file) => file.upload === null).length)) *
                              100
                            }
                            strokeWidth="22"
                            trailColor="#E0E0E0"
                            trailWidth="22"
                            strokeColor={renderPercentageColor(
                              (item.fileRequirements.filter((file) => file.upload !== null).length /
                                (item.fileRequirements.filter((file) => file.upload !== null).length +
                                  item.fileRequirements.filter((file) => file.upload === null).length)) *
                              100
                            )}
                          />
                          <label style={{ textAlign: 'center' }}>
                            {item.fileRequirements.filter((file) => file.upload !== null).length}/
                            {item.fileRequirements.length}
                          </label>
                        </td>
                        <td>{item.assignment ? 'Assigned' : 'No Assigned'}</td>
                        <td>
                          <div>
                            <Link to={`/project/${item.ID}/update`}>
                              <img src={IconEdit} />
                            </Link>
                            {params.deleted == 1 ? (
                              <img src={IconRefresh} onClick={() => rollbackProject(item.ID)} />
                            ) : (
                              <img
                                src={IconTrash}
                                onClick={() => {
                                  setselectedItemDelete({
                                    id: item.ID,
                                    name: item.name,
                                  })
                                  setIsModalDeleteShow(true)
                                }}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })
                )}
              </>
            ),
          }}
        />
      </div>

      {/* Delete*/}
      <ModalCreate isOpen={isModalDeleteShow && selectedItemDelete !== null} onRequestClose={closeModalDelete}>
        <div className="formModal">
          <h2 style={{ fontSize: 14 }}>
            Yakin ingin menghapus <b>{selectedItemDelete?.name} </b>?
          </h2>
          <div>
            <button onClick={closeModalDelete}>Cancel</button>
            <button onClick={() => deleteProject()}>{isDeleting ? 'Menghapus...' : 'Ya, Hapus'} </button>
          </div>
        </div>
      </ModalCreate>
    </Layout>
  )
}

export default Pengadaan
