import { Route, Redirect } from "react-router-dom";
import LandingPage from "./Pages/Landing";
import Login from "./Pages/Login";
import Pengadaan from "./Pages/Pengadaan";
import AssignPengadaan from "./Pages/Pengadaan/Assign";
import ReportPengadaan from "./Pages/Pengadaan/Report";
import DetailPengadaan from "./Pages/Pengadaan/Detail";
import UpdateAssignPengadaan from "./Pages/Pengadaan/Assign/Update";
import CreatePengadaan from "./Pages/Pengadaan/Create";
import UpdatePengadaan from "./Pages/Pengadaan/Update";
import Home from "./Pages/Home";
import Rekanan from "./Pages/Rekanan";
import UpdateRekanan from "./Pages/Rekanan/Update";
import CreateRekanan from "./Pages/Rekanan/Create";

import Division from "./Pages/Division";

import { isLoggedIn } from "./Services/Authentication";

const Routes = () => {
  return (
    <>
      <Route path="/project" exact component={Pengadaan} />
      <Route path="/project/assign" component={AssignPengadaan} />
      <Route path="/project/report" component={ReportPengadaan} />
      <Route path="/project/:id/assign" component={UpdateAssignPengadaan} />
      <Route path="/project/:id/detail" component={DetailPengadaan} />
      <Route path="/project/:id/update" component={UpdatePengadaan} />
      <Route path="/project/create" component={CreatePengadaan} />

      <Route path="/home">
        {isLoggedIn() ? <Home /> : <Redirect to="/login" />}
      </Route>

      {/* <Route path="/home" component={Home} /> */}

      <Route path="/login" component={Login} />

      <Route path="/pokja" exact component={Division} />

      <Route path="/rekanan" exact component={Rekanan} />
      <Route path="/rekanan/create" component={CreateRekanan} />
      <Route path="/rekanan/:id/update" component={UpdateRekanan} />

      <Route exact path="/" component={LandingPage} />
    </>
  );
};

export default Routes;
