import IconSearch from '../Assets/icons/search.png'
import { Link } from 'react-router-dom'
import _ from 'lodash'

const Table = ({
  table,
  isSearch = true,
  isAdd = true,
  isExport = true,
  isFilter = false,
  filterAction = () => null,
  isPagination = true,
  buttonAddName,
  isModal = false,
  isModalAction,
  actionAdd,
  pagination = {
    currentPage: 1,
    pageCount: 1,
    prevAction: () => null,
    nextAction: () => null,
  },
  onSearch = () => null,
}) => {
  return (
    <div className="tableWrapper">
      <div className="tableWrapper__header">
        <section className="tableWrapper__header__left">
          {isSearch ? (
            <div>
              <img src={IconSearch} />
              {/* <span>Search</span> */}
              <input
                type="text"
                placeholder="Search..."
                onChange={_.debounce(({ target: { value } }) => onSearch(value), 1000)}
              />
            </div>
          ) : null}

          {isFilter ? (
            <div>
              <select onChange={({ target: { value } }) => filterAction(value)}>
                <option value={0}>Non Archive</option>
                <option value={1}>Archive</option>
              </select>
            </div>
          ) : null}

          {/* {isFilter ? (
            <div>
              <select>
                <option>{">"} 50 Juta</option>
                <option>{"<"} 50 Juta</option>
              </select>
            </div>
          ) : null} */}
        </section>

        <section className="tableWrapper__header__right">
          {/* Export is Hide Now */}
          {/* {isExport ? (
            <>
              <div>Export XLS</div>
              <div>Export PDF</div>
            </>
          ) : null} */}
          {isAdd ? (
            isModal ? (
              <div onClick={() => isModalAction()}>
                <a>Buat {buttonAddName} +</a>
              </div>
            ) : (
              <Link to={actionAdd}>Buat {buttonAddName} +</Link>
            )
          ) : null}
        </section>
      </div>

      {isPagination ? (
        <div className="tableWrapper__footer">
          <div onClick={() => pagination.prevAction()}>{'<'}</div>
          <span>
            {pagination.currentPage} / {pagination.pageCount}
          </span>
          <div onClick={() => pagination.nextAction()}>{'>'}</div>
        </div>
      ) : null}

      <br />

      <table>
        <tr className="title">{table.title}</tr>
        {table.item}
        {/* <tr className="item">{table.item}</tr> */}
      </table>
    </div>
  )
}

export default Table
