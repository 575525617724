import { useState, useEffect } from 'react'
import Layout from '../../Layout'
import Table from '../../Components/Table'
import IconEdit from '../../Assets/icons/edit.png'
import IconTrash from '../../Assets/icons/trash_empty.png'
import IconRefresh from '../../Assets/icons/refresh.png'
import ModalCreate from '../../Components/ModalCreate'
import Handler from './Handler'
import API from '../../Services/API'

import BeatLoader from 'react-spinners/BeatLoader'
import { Link } from 'react-router-dom'

const Rekanan = () => {
  const [isShowModalCreateOperator, setIsShowModalCreateOperator] = useState(false)

  const [isShowModalUpdateOperator, setIsShowModalUpdateOperator] = useState(false)

  const [listHandler, setlistHandler] = useState({
    isLoading: true,
    items: [],
  })

  const [isModalDeleteShow, setIsModalDeleteShow] = useState(false)
  const [selectedItemDelete, setselectedItemDelete] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)

  const closeModalUpdate = () => {
    setIsShowModalUpdateOperator(false)
  }

  const closeModal = () => {
    setIsShowModalCreateOperator(false)
  }

  const closeModalDelete = () => {
    setIsModalDeleteShow(false)
    setselectedItemDelete(null)
  }

  const openModal = () => {
    setIsShowModalCreateOperator(true)
  }

  // Rekanan
  const [params, setParams] = useState({
    currentPage: 1,
    pageSize: 20,
    keyword: null,
    isArchived: 0,
    expand: 'handlers',
  })

  const [listVendor, setlistVendor] = useState({
    isLoading: true,
    items: [],
  })

  const [isMore, setIsmore] = useState(false)
  const [pageCount, setPageCount] = useState(1)

  const fetchListHandler = () => {
    API.listHandler({
      pageSize: 1000,
    }).then((response) => {
      if (response.code === 200) {
        setlistHandler({
          isLoading: false,
          items: response.data.list,
        })
        setIsmore(response.data.pagination.isMore)
        setPageCount(response.data.pagination.pageCount)
      }
    })
  }

  const fetchlistVendor = () => {
    API.listVendor(params).then((response) => {
      if (response.code === 200) {
        setlistVendor({
          isLoading: false,
          items: response.data.list,
        })
        setIsmore(response.data.pagination.isMore)
        setPageCount(response.data.pagination.pageCount)
      }
    })
  }

  const prevPage = () => {
    if (params.currentPage > 1) {
      setParams((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage - 1,
      }))
    }
  }

  const nextPage = () => {
    if (isMore) {
      setParams((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage + 1,
      }))
    }
  }

  const onSearch = (value) => {
    setParams((prevState) => ({
      ...prevState,
      keyword: value,
    }))
  }

  useEffect(() => {
    fetchlistVendor()
    fetchListHandler()
  }, [params.currentPage, params.keyword, params.isArchived])

  const deleteVendor = () => {
    setIsDeleting(true)

    API.archiveVendor(selectedItemDelete.id).then((response) => {
      if (response.code === 200) {
        setIsDeleting(false)
        setIsModalDeleteShow(false)
        setselectedItemDelete(null)
        fetchlistVendor()
      }
    })
  }

  const rollbackVendor = (id) => {
    API.archiveVendor(id).then((response) => {
      if (response.code === 200) {
        fetchlistVendor()
      }
    })
  }

  const filter = (value) => {
    setParams((prevState) => ({ ...prevState, isArchived: value }))
  }

  return (
    <Layout>
      <div className="rekanan">
        <div className="rekanan__table">
          <section className="rekanan__table__rekanan">
            <h2>Table Rekanan</h2>
            <Table
              actionAdd="/rekanan/create"
              buttonAddName="Rekanan"
              isFilter={true}
              filterAction={filter}
              pagination={{
                currentPage: params.currentPage,
                pageCount: pageCount,
                prevAction: prevPage,
                nextAction: nextPage,
              }}
              onSearch={onSearch}
              table={{
                title: (
                  <>
                    <td>No.</td>
                    <td>Rekanan</td>
                    <td>No. NPWP</td>
                    <td>Handler</td>
                    <td>Aksi</td>
                  </>
                ),
                item: (
                  <>
                    {listVendor.isLoading ? (
                      <tr className="item">
                        <td colSpan="14" className="emptyState">
                          <BeatLoader size={6} color="#2962FF" />
                        </td>
                      </tr>
                    ) : listVendor.items.length < 1 ? (
                      <tr className="item">
                        <td colSpan="14" className="emptyState">
                          No Data
                        </td>
                      </tr>
                    ) : (
                      listVendor.items.map((item, index) => {
                        return (
                          <tr className="item">
                            <td style={{ textAlign: 'center' }}>
                              {params.currentPage * params.pageSize + (index - params.pageSize + 1)}
                            </td>
                            <td>{item.name}</td>
                            <td>{item.NPWP}</td>
                            <td>
                              <ul>
                                {/* {listHandler.isLoading ? (
                                  <li>Loading...</li>
                                ) : null} */}

                                {item.handlers.length < 1
                                  ? '-'
                                  : item.handlers.map((handler) => {
                                      return <li>{handler.name}</li>
                                    })}
                              </ul>
                            </td>

                            <td>
                              <div>
                                <Link to={`/rekanan/${item.ID}/update`}>
                                  <img src={IconEdit} />
                                </Link>
                                {params.isArchived == 1 ? (
                                  <img src={IconRefresh} onClick={() => rollbackVendor(item.ID)} />
                                ) : (
                                  <img
                                    src={IconTrash}
                                    onClick={() => {
                                      setselectedItemDelete({
                                        id: item.ID,
                                        name: item.name,
                                      })
                                      setIsModalDeleteShow(true)
                                    }}
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    )}
                  </>
                ),
              }}
            />
          </section>
          <section className="rekanan__table__operator">
            <Handler fetchlistRekanan={fetchlistVendor} />
          </section>
        </div>
        {/* DOWNLOAD RESPORT  */}

        {/* <div className="rekanan__report">
          <h2>Donwload Report</h2>
          <section>
            <h2>Report Of Operator</h2>
            <div>
              <select>
                <option>Abdul Haris</option>
              </select>
            </div>
            <section>
              <button>Download XLS</button>
              <button>Download PDF</button>
            </section>
          </section>
        </div> */}
      </div>

      <ModalCreate isOpen={isShowModalCreateOperator} onRequestClose={closeModal}>
        <div className="formModal">
          <h2>Tambah Operator</h2>
          <section>
            <input type="text" placeholder="Nama Operator" />
          </section>
          <div>
            <button onClick={() => setIsShowModalCreateOperator(false)}>Cancel</button>
            <button>Simpan</button>
          </div>
        </div>
      </ModalCreate>

      <ModalCreate isOpen={isShowModalUpdateOperator} onRequestClose={closeModalUpdate}>
        <div className="formModal">
          <h2>Update Operator</h2>
          <section>
            <input type="text" placeholder="Rekanan" />
          </section>
          <section>
            <input type="text" placeholder="Nama Operator" />
          </section>
          <div>
            <button onClick={() => setIsShowModalUpdateOperator(false)}>Cancel</button>
            <button>Simpan</button>
          </div>
        </div>
      </ModalCreate>

      {/* Delete Rekanan*/}
      <ModalCreate isOpen={isModalDeleteShow && selectedItemDelete !== null} onRequestClose={closeModalDelete}>
        <div className="formModal">
          <h2 style={{ fontSize: 14 }}>
            Yakin ingin menghapus <b>{selectedItemDelete?.name} </b>?
          </h2>
          {/* <section>
            <input type="text" placeholder="Nama Operator" />
          </section> */}
          {/* <p>Yakin Mau Menghapus ?</p> */}
          <div>
            <button onClick={closeModalDelete}>Cancel</button>
            <button onClick={() => deleteVendor()}>{isDeleting ? 'Menghapus...' : 'Ya, Hapus'} </button>
          </div>
        </div>
      </ModalCreate>
    </Layout>
  )
}

export default Rekanan
