import { useState, useEffect } from 'react'
import Table from '../../Components/Table'
import IconEdit from '../../Assets/icons/edit.png'
import IconTrash from '../../Assets/icons/trash_empty.png'
import API from '../../Services/API'
import ModalCreate from '../../Components/ModalCreate'
import Select from 'react-select'
import BeatLoader from 'react-spinners/BeatLoader'
import { setAppElement } from 'react-modal'
import { locale } from 'moment'

const Handler = ({ fetchlistRekanan }) => {
  const [isShowModalCreateHandler, setIsShowModalCreateHandler] = useState(false)

  const [isShowModalUpdateHandler, setIsShowModalUpdateHandler] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')

  const [isModalDeleteShow, setIsModalDeleteShow] = useState(false)
  const [selectedItemDelete, setselectedItemDelete] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)

  const [vendorSeleted, setVendorSeleted] = useState([])
  const [vendorDeleted, setVendorDeleted] = useState([])

  const [params, setParams] = useState({
    currentPage: 1,
    pageSize: 20,
    keyword: null,
  })

  const [vendors, setVendors] = useState({
    isLoading: true,
    items: [],
  })

  const [listHandler, setlistHandler] = useState({
    isLoading: true,
    items: [],
  })

  const [data, setData] = useState({
    ID: null,
    name: null,
    // defaultDivision: null,
  })

  const [isSubmiting, setIsSubmiting] = useState(false)

  const [isMore, setIsmore] = useState(false)
  const [pageCount, setPageCount] = useState(1)

  const [listProjectHandler, setListProjectHandler] = useState([])

  const closeModalDelete = () => {
    setIsModalDeleteShow(false)
    setselectedItemDelete(null)
  }

  const deleteHandler = () => {
    setIsDeleting(true)

    API.archiveHandler(selectedItemDelete.id).then((response) => {
      if (response.code === 200) {
        setIsDeleting(false)
        setIsModalDeleteShow(false)
        setselectedItemDelete(null)
        fetchListHandler()
      }
    })
  }

  const fetchListHandler = () => {
    API.listHandler({
      currentPage: params.currentPage,
      pageSize: params.pageSize,
      keyword: params.keyword,
    }).then((response) => {
      if (response.code === 200) {
        setlistHandler({
          isLoading: false,
          items: response.data.list,
        })
        setIsmore(response.data.pagination.isMore)
        setPageCount(response.data.pagination.pageCount)
      }
    })
  }

  const prevPage = () => {
    if (params.currentPage > 1) {
      setParams((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage - 1,
      }))
    }
  }

  const nextPage = () => {
    if (isMore) {
      setParams((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage + 1,
      }))
    }
  }

  const onSearch = (value) => {
    setParams((prevState) => ({
      ...prevState,
      keyword: value,
    }))
  }

  const fetchListPengadaanHandling = () => {
    API.listPengadaan({
      pageSize: 100,
    }).then((response) => {
      if (response.code === 200) {
        setListProjectHandler(response.data.list)
      }
    })
  }

  const fetchlistVendor = () => {
    API.listVendor({
      pageSize: 200,
      isArchived: 0,
    }).then((response) => {
      if (response.code === 200) {
        setVendors({
          isLoading: false,
          items: response.data.list,
        })
      }
    })
  }

  useEffect(() => {
    fetchListPengadaanHandling()
    fetchlistVendor()
  }, [])

  useEffect(() => {
    fetchListHandler()
  }, [params.currentPage, params.keyword])

  const closeModalUpdate = () => {
    setIsShowModalUpdateHandler(false)
  }

  const closeModal = () => {
    setIsShowModalCreateHandler(false)
  }

  const openModal = () => {
    setIsShowModalCreateHandler(true)
  }

  const fetchDetailHandler = (id) => {
    API.detailHandler(id).then((response) => {
      if (response.code === 200) {
        setData((prevState) => ({
          ...prevState,
          name: response.data.name,
          ID: response.data.ID,
        }))

        setVendorSeleted(response.data.vendors)
      }
    })
  }

  const createHandler = () => {
    setIsSubmiting(true)

    let assignVendor = {}
    let allVendorAssigned = vendorSeleted
    for (let i = 0; i < allVendorAssigned.length; i++) {
      assignVendor = {
        ...assignVendor,
        ...{ [`assigns[${i}]`]: allVendorAssigned[i].ID },
      }
    }

    API.createHandler({
      name: data.name,
      defaultDivisionID: data.defaultDivision,
    }).then((response) => {
      if (response.code === 200) {
        API.assignVendorHandler(response.data.ID, assignVendor).then((response) => {
          if (response.code === 200) {
            fetchListHandler()
            fetchlistRekanan()
          }
        })
        setIsShowModalCreateHandler(false)
      } else {
        setErrorMessage('Nama Tidak Boleh Melebihi 50 Karakter !')
      }

      setIsSubmiting(false)
      setVendorSeleted([])
    })
  }

  const updateHandler = () => {
    setIsSubmiting(true)

    let assignVendor = {}
    let allVendorAssigned = vendorSeleted
    for (let i = 0; i < allVendorAssigned.length; i++) {
      assignVendor = {
        ...assignVendor,
        ...{ [`assigns[${i}]`]: allVendorAssigned[i].ID },
      }
    }

    let assignVendorDeleted = {}
    let allVendorAssignedDeleted = vendorDeleted
    for (let i = 0; i < allVendorAssignedDeleted.length; i++) {
      assignVendorDeleted = {
        ...assignVendorDeleted,
        ...{ [`removes[${i}]`]: allVendorAssignedDeleted[i].ID },
      }
    }

    API.updateHandler(data.ID, {
      name: data.name,
      defaultDivisionID: data.defaultDivision,
    }).then((response) => {
      if (response.code === 200) {
        API.assignVendorHandler(response.data.ID, {
          ...assignVendor,
          ...assignVendorDeleted,
        }).then((response) => {
          if (response.code === 200) {
            fetchListHandler()
            fetchlistRekanan()
          }
        })
        setIsShowModalUpdateHandler(false)
      } else {
        setErrorMessage('Nama Tidak Boleh Melebihi 50 Karakter !')
      }
      setIsSubmiting(false)
      setVendorSeleted([])
      setVendorDeleted([])
    })
  }

  const openModalUpdate = (id) => {
    setData({
      ID: null,
      defaultDivision: null,
      name: 'Loading...',
    })
    fetchDetailHandler(id)
    setIsShowModalUpdateHandler(true)
  }

  return (
    <>
      <h2>Table List Handler</h2>
      <Table
        isModal={true}
        isModalAction={openModal}
        buttonAddName="Handler"
        isExport={false}
        pagination={{
          currentPage: params.currentPage,
          pageCount: pageCount,
          prevAction: prevPage,
          nextAction: nextPage,
        }}
        onSearch={onSearch}
        table={{
          title: (
            <>
              <td>No.</td>
              <td>Nama Handler</td>
              {/* <td>Email</td>
              <td>No.HP</td> */}
              <td>Total Rekanan</td>
              <td>Aksi</td>
            </>
          ),
          item: (
            <>
              {listHandler.isLoading ? (
                <tr className="item">
                  <td colSpan="6" className="emptyState">
                    <BeatLoader size={6} color="#2962FF" />
                  </td>
                </tr>
              ) : listHandler.items.length < 1 ? (
                <tr className="item">
                  <td colSpan="6" className="emptyState">
                    No Data
                  </td>
                </tr>
              ) : (
                listHandler.items.map((item, index) => {
                  return (
                    <tr className="item">
                      <td style={{ textAlign: 'center' }}>
                        {params.currentPage * params.pageSize + (index - params.pageSize + 1)}
                      </td>
                      <td>{item.name}</td>
                      {/* <td>{item.account?.email || "-"}</td>
                      <td>-</td> */}
                      <td style={{ textAlign: 'center' }}>{item.vendors?.length || 0}</td>
                      <td>
                        <div>
                          <img src={IconEdit} onClick={() => openModalUpdate(item.ID)} />
                          <img
                            src={IconTrash}
                            onClick={() => {
                              setselectedItemDelete({
                                id: item.ID,
                                name: item.name,
                              })
                              setIsModalDeleteShow(true)
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  )
                })
              )}
            </>
          ),
        }}
      />
      {/* create */}
      <ModalCreate isOpen={isShowModalCreateHandler} onRequestClose={closeModal}>
        <div className="formModal">
          <h2>Tambah Handler</h2>
          <aside>
            <h3>Nama</h3>
            <section>
              <input
                type="text"
                placeholder="Nama Handler"
                onChange={({ target: { value } }) =>
                  setData((prevState) => ({
                    ...prevState,
                    name: value,
                  }))
                }
              />
            </section>
          </aside>
          <p style={{ color: 'red', fontSize: 10, marginTop: 10 }}>{errorMessage}</p>
          <aside>
            <h3>Pilih Rekanan</h3>
            <section>
              <Select
                placeholder="--Pilih Rekanan--"
                className="selectSearch"
                onChange={(selectedOption) => {
                  setVendorSeleted((prevState) => [
                    ...prevState,
                    {
                      ID: selectedOption.value,
                      name: selectedOption.label,
                    },
                  ])
                }}
                options={
                  vendors.isLoading
                    ? [{ label: 'Loading' }]
                    : vendors.items.map((item) => {
                        return { value: item.ID, label: item.name }
                      })
                }
              />
            </section>
          </aside>
          <aside>
            {vendorSeleted.map((vendorItem) => {
              return (
                <section style={{ display: 'flex', alignItems: 'center' }}>
                  <input type="text" value={vendorItem.name} disabled />
                  <a
                    style={{ fontSize: 12, color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      console.log('Value', vendorItem)

                      if (vendorSeleted.includes(vendorItem)) {
                        const filter = vendorSeleted.filter((vendor) => vendor != vendorItem)

                        setTimeout(() => {
                          setVendorSeleted(filter)
                        }, 500)
                      }
                    }}
                  >
                    Delete
                  </a>
                </section>
              )
            })}
          </aside>
          <div>
            <button
              onClick={() => {
                setErrorMessage('')
                setIsShowModalCreateHandler(false)
              }}
            >
              Cancel
            </button>
            <button disabled={data.name === null} onClick={() => createHandler()}>
              {isSubmiting ? <BeatLoader size={6} color="#2962FF" /> : 'Simpan'}
            </button>
          </div>
        </div>
      </ModalCreate>

      {/* Update */}
      <ModalCreate isOpen={isShowModalUpdateHandler} onRequestClose={closeModalUpdate}>
        <div className="formModal">
          <h2>Update Handler</h2>
          <aside>
            <h3>Nama</h3>
            <section>
              <input
                type="text"
                placeholder="Nama Handler"
                value={data.name}
                onChange={({ target: { value } }) =>
                  setData((prevState) => ({
                    ...prevState,
                    name: value,
                  }))
                }
              />
            </section>
          </aside>
          <p style={{ color: 'red', fontSize: 10, marginTop: 10 }}>{errorMessage}</p>
          <aside>
            <h3>Pilih Rekanan</h3>
            <section>
              <Select
                placeholder="--Pilih Rekanan--"
                className="selectSearch"
                onChange={(selectedOption) => {
                  setVendorSeleted((prevState) => [
                    ...prevState,
                    {
                      ID: selectedOption.value,
                      name: selectedOption.label,
                    },
                  ])
                }}
                options={
                  vendors.isLoading
                    ? [{ label: 'Loading' }]
                    : vendors.items.map((item) => {
                        return { value: item.ID, label: item.name }
                      })
                }
              />
            </section>
          </aside>

          <aside>
            {vendorSeleted.map((vendorItem) => {
              return (
                <section style={{ display: 'flex', alignItems: 'center' }}>
                  <input type="text" value={vendorItem.name} disabled />
                  <a
                    style={{ fontSize: 12, color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      console.log('Value', vendorItem)

                      if (vendorSeleted.includes(vendorItem)) {
                        const filter = vendorSeleted.filter((vendor) => vendor != vendorItem)

                        setVendorDeleted((prevState) => [
                          ...prevState,
                          {
                            ID: vendorItem.ID,
                            name: vendorItem.name,
                          },
                        ])

                        setTimeout(() => {
                          setVendorSeleted(filter)
                        }, 500)
                      }
                    }}
                  >
                    Delete
                  </a>
                </section>
              )
            })}
          </aside>

          <div>
            <button
              onClick={() => {
                setErrorMessage('')
                setIsShowModalUpdateHandler(false)
              }}
            >
              Cancel
            </button>
            <button onClick={() => updateHandler()} disabled={data.ID === null}>
              {isSubmiting ? <BeatLoader size={6} color="#2962FF" /> : 'Simpan'}
            </button>
          </div>
        </div>
      </ModalCreate>

      {/* Delete*/}
      <ModalCreate isOpen={isModalDeleteShow && selectedItemDelete !== null} onRequestClose={closeModalDelete}>
        <div className="formModal">
          <h2 style={{ fontSize: 14 }}>
            Yakin ingin menghapus <b>{selectedItemDelete?.name} </b>?
          </h2>
          <div>
            <button onClick={closeModalDelete}>Cancel</button>
            <button onClick={() => deleteHandler()}>{isDeleting ? 'Menghapus...' : 'Ya, Hapus'} </button>
          </div>
        </div>
      </ModalCreate>
    </>
  )
}

export default Handler
