import { useState } from "react";
import Header from "./header";
import Sidebar from "./sidebar";
import { isMobile } from "react-device-detect";

export default ({ children }) => {
  const [isLeftContentShow, setIsLeftContentShow] = useState(false);

  const onClose = () => {
    setIsLeftContentShow(false);
  };

  const onOpen = () => {
    setIsLeftContentShow(true);
  };

  return (
    <div className="layout">
      <div
        className="layout__left"
        style={{
          position: isMobile ? "absolute" : "static",
          width: isMobile ? "100%" : 320,
          display: isMobile && !isLeftContentShow ? "none" : "block",
        }}
      >
        <Sidebar onClose={onClose} />
      </div>
      <div className="layout__right">
        <Header onOpen={onOpen} />
        {children}
      </div>
    </div>
  );
}