import Layout from "../../../Layout";

const Report = () => {
  return (
    <Layout>
      <div className="reportPengadaan">
        <h2>Download Laporan Pengadaan</h2>
        <div>
          <section>
            <h2>By Group Pengadaan</h2>
            <div>
              <select>
                <option>{">"} 50 Juta</option>
                <option>{"<"} 50 Juta</option>
              </select>
            </div>
            <section>
              <button>Download XLS</button>
              <button>Download PDF</button>
            </section>
          </section>

          <section>
            <h2>By Spending Pengadaan</h2>
            <div>
              <select>
                <option>Belanja Barang</option>
              </select>
            </div>
            <section>
              <button>Download XLS</button>
              <button>Download PDF</button>
            </section>
          </section>

          <section>
            <h2>By Sibaja Pengadaan</h2>
            <div>
              <select>
                <option>Undone</option>
                <option>Done</option>
              </select>
            </div>
            <section>
              <button>Download XLS</button>
              <button>Download PDF</button>
            </section>
          </section>

          <section>
            <h2>By Divis Pengadaan</h2>
            <div>
              <select>
                <option>Bagian Keuangan dan BMN</option>
                <option>Operational</option>
              </select>
            </div>
            <section>
              <button>Download XLS</button>
              <button>Download PDF</button>
            </section>
          </section>

          <section>
            <h2>By Operator</h2>
            <div>
              <select>
                <option>Abdul Haris</option>
              </select>
            </div>
            <section>
              <button>Download XLS</button>
              <button>Download PDF</button>
            </section>
          </section>

          <section>
            <h2>By PIC</h2>
            <div>
              <select>
                <option>Abdul Haris</option>
              </select>
            </div>
            <section>
              <button>Download XLS</button>
              <button>Download PDF</button>
            </section>
          </section>

          <section>
            <h2>By Date</h2>
            <div>
              <input type="date" />
            </div>
            <div>
              <select>
                <option>Abdul Haris</option>
              </select>
            </div>
            <section>
              <button>Download XLS</button>
              <button>Download PDF</button>
            </section>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default Report;
