import { useState, useEffect } from "react";
import Layout from "../../Layout";
import API from "../../Services/API";
import DownloadIcon from "../../Assets/icons/cloud_down.png";
import UploadIcon from "../../Assets/icons/cloud_up.png";
import BeatLoader from "react-spinners/BeatLoader";

const Detail = ({ match, history }) => {
  const [fileUpload, setfileUpload] = useState({
    id: null,
    file: null,
  });

  const [data, setData] = useState({
    isLoading: true,
    item: null,
  });

  const ID = match.params.id;

  const fetchDetail = () => {
    API.detailPengadaan(ID).then((response) => {
      if (response.code === 200) {
        setData({
          isLoading: false,
          item: response.data,
        });
      }
    });
  };

  const uploadFileRequirement = () => {
    if (fileUpload.id) {
      API.uploadFileRequirement(fileUpload.id, { file: fileUpload.file }).then(
        (response) => {
          if (response.code === 200) {
            setfileUpload({
              id: null,
              file: null,
            });
            fetchDetail();
          }
        }
      );
    }
  };

  useEffect(() => {
    fetchDetail();
  }, []);

  let fileIDS = [];

  return (
    <Layout>
      <div className="createPengadaan">
        <h2>Detail Pengadaan</h2>
        <div className="createPengadaan__form">
          <div className="createPengadaan__form__detail">
            <div>
              <button onClick={() => history.push(`/project/${ID}/update`)}>
                Update
              </button>
            </div>

            <h3>
              {data.isLoading ? (
                <BeatLoader size={6} color="#2962FF" />
              ) : (
                data.item.name
              )}
            </h3>
            {data.isLoading ? null : (
              <table>
                <tr>
                  <td>Handler</td>
                  <td>:</td>
                  <td>{data.item.handler?.name || "-"}</td>
                </tr>
                <tr>
                  <td>Pokja</td>
                  <td>:</td>
                  <td>{data.item.division.name}</td>
                </tr>
                <tr>
                  <td>Spending</td>
                  <td>:</td>
                  <td>{data.item.expenseType.name}</td>
                </tr>

                <tr>
                  <td>Procurement Officer / PP</td>
                  <td>:</td>
                  <td>{data.item.procurementOfficer.name}</td>
                </tr>
                <tr>
                  <td>Deskripsi</td>
                  <td>:</td>
                  <td>{data.item.assignment?.description || "-"}</td>
                </tr>
                <tr>
                  <td>HPS</td>
                  <td>:</td>
                  <td>
                    <b>
                      Rp{" "}
                      {new Intl.NumberFormat("id").format(
                        data.item.ownerEstimatePrice
                      )}
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>Nilai Kontrak</td>
                  <td>:</td>
                  <td>
                    <b>
                      {data.item.assignment === null
                        ? "-"
                        : `
                       Rp
                       ${new Intl.NumberFormat("id").format(
                         data.item.assignment.contractPrice
                       )}
                    `}
                    </b>
                  </td>
                </tr>
              </table>
            )}
          </div>
        </div>
      </div>
      <div className="createPengadaan" style={{ marginTop: 32 }}>
        <h2>File Requirement</h2>

        {/* <div className="createPengadaan__file">
          <aside className="createPengadaan__file__kontrak">
            <label>No Kontrak</label>
            <section>
              <input type="text" placeholder="No Kontrak" />
            </section>
          </aside>
          <aside className="createPengadaan__file__kontrak">
            <label>Tanggal Kontrak</label>
            <section>
              <input type="date" placeholder="Tanggal Kontrak" />
            </section>
          </aside>
          <section>
            <button>Submit</button>
          </section>
        </div> */}

        <div className="createPengadaan__file">
          <table>
            <tr>
              <td>Upload</td>
              <td>Nama File</td>
              <td>Status</td>
              <td>Aksi</td>
            </tr>
            {data.isLoading ? (
              <tr>
                <td colSpan="4">
                  <BeatLoader size={6} color="#2962FF" />
                </td>
              </tr>
            ) : data.item.fileRequirements.length < 1 ? (
              <tr>
                <td colSpan="4" style={{ textAlign: "center", padding: 20 }}>
                  Tidak Ada File Requirement
                </td>
              </tr>
            ) : (
              data.item.fileRequirements.map((item, index) => {
                fileIDS.push(item.ID);
                return (
                  <tr>
                    <td>
                      <div
                        id="dropContainer"
                        style={{
                          width: "100%",
                          height: 50,
                          border: "1px solid #eeeeee",
                          borderStyle: "dashed",
                          backgroundColor: "#fafafa",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: 10,
                        }}
                        onDragOver={(event) => {
                          event.preventDefault();
                        }}
                        onDrop={(event) => {
                          let fileInput = document.getElementById(
                            `fileInput${item.ID}`
                          );

                          // pretty simple -- but not for IE :(
                          fileInput.files = event.dataTransfer.files;

                          const dT = new DataTransfer();
                          dT.items.add(event.dataTransfer.files[0]);
                          fileInput.files = dT.files;

                          setfileUpload({
                            id: item.ID,
                            file: event.dataTransfer.files[0],
                          });

                          event.preventDefault();
                        }}
                      >
                        Drop File Here
                      </div>
                      <input
                        id={`fileInput${item.ID}`}
                        type="file"
                        onChange={({ target: { files } }) => {
                          setfileUpload({
                            id: item.ID,
                            file: files[0],
                          });
                        }}
                      />
                    </td>
                    <td>{item.requirement.name}</td>
                    <td
                      style={{ color: item.upload === null ? "red" : "green" }}
                    >
                      {item.upload === null
                        ? "Belum Di Upload"
                        : "Sudah Di Upload"}
                    </td>
                    <td>
                      <img
                        src={UploadIcon}
                        title="Upload File"
                        style={{ marginRight: 10 }}
                        onClick={() => uploadFileRequirement()}
                      />
                      {item.upload ? (
                        <a
                          href={item.upload.url}
                          target="_blank"
                          rel="noopener"
                        >
                          <img src={DownloadIcon} title="Download File" />
                        </a>
                      ) : null}
                    </td>
                  </tr>
                );
              })
            )}
          </table>

          {/* <div>
            <input type="file" />
            <label style={{ marginRight: 0 }}>File 1</label>
            <img src={DownloadIcon} title="Download File" />
          </div>

          <div>
            <input type="file" />
            <label style={{ marginRight: 0 }}>File 1</label>
            <img src={DownloadIcon} title="Download File" />
          </div> */}
        </div>
      </div>
    </Layout>
  );
};

export default Detail;
