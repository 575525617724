
import { ReactComponent as IconCalendar } from "../Assets/icons/calendar.svg";
import IconMenu from "../Assets/icons/menu_alt_03.png";
import UserDummy from "../Assets/images/anya.png";
import UserDummyProfile from "../Assets/images/profile.png";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { getSelectedYear } from "../Services/Authentication";

const Header = ({ onOpen }) => {
  const location = useLocation();
  const currentTitle = location.pathname.split("/")[1];
  return (
    <div className="header">
      {isMobile ? (
        <img src={IconMenu} alt="" onClick={() => onOpen()} />
      ) : (
        <h2>{currentTitle === "project" ? "Pengadaan" : currentTitle}</h2>
      )}

      <div>
        <section>
          <IconCalendar /> <small style={{ marginLeft: 10 }}>Tahun {getSelectedYear()}</small>
        </section>
        <div>
          <span>
            {
              localStorage.getItem("email") ?
                <>
                  Login Sebagai <b>{localStorage.getItem("email")}</b>
                </> :
                'Masuk'
            }
          </span>
          <img src={UserDummyProfile} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Header;
