import queryString from 'query-string'
import { getToken } from './Authentication'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export default class API {
  // Grafik
  static listPricePerYear = (parameters = {}) => {
    const payload = this.cleanPayload({
      year: parameters.year,
    })
    return this.fetch(`procurement/statistic/price/year?${queryString.stringify(payload)}`)
  }
  // Pengadaan
  static listPengadaan = (parameters = {}) => {
    const payload = this.cleanPayload({
      currentPage: parameters.currentPage || 1,
      pageSize: parameters.pageSize || 20,
      keyword: parameters.keyword,
      price: parameters.price,
      year: parameters.year,
      order: parameters.order || 'desc',
      sort: parameters.sort || 'created',
      deleted: parameters.deleted,
      projectAssignment: parameters.projectAssignment,
      expand: 'procurementOfficer,handler',
      procurementOfficerID: parameters.procurementOfficerID,
    })
    return this.fetch(`procurement/project?${queryString.stringify(payload)}`)
  }

  static detailPengadaan = (id) => {
    const payload = this.cleanPayload({
      expand: 'procurementOfficer,handler',
    })

    return this.fetch(`procurement/project/${id}?${queryString.stringify(payload)}`)
  }

  static createPengadaan = (body = {}) => {
    const reqBody = this.cleanPayload({
      name: body.name,
      handlerID: body.handlerID,
      divisionID: body.divisionID,
      ownerEstimatePrice: body.ownerEstimatePrice,
      expenseTypeID: body.expenseTypeID,
      procurementOfficerID: body.procurementOfficerID,
      year: body.year,
    })
    return this.fetch('procurement/project', { body: reqBody, method: 'POST' })
  }

  static updatePengadaan = (id, body = {}) => {
    const reqBody = this.cleanPayload({
      name: body.name,
      handlerID: body.handlerID,
      divisionID: body.divisionID,
      ownerEstimatePrice: body.ownerEstimatePrice,
    })
    return this.fetch(`procurement/project/${id}`, {
      body: reqBody,
      method: 'POST',
    })
  }

  static archivePengadaan = (id, body = {}) => {
    const reqBody = this.cleanPayload({
      deleted: body.deleted,
    })

    return this.fetch(`procurement/project/${id}/archive`, {
      body: reqBody,
      method: 'POST',
    })
  }

  // Assign
  static assignVendor = (id, body = {}) => {
    const reqBody = this.cleanPayload({
      code: body.code,
      vendorID: body.vendorID,
      contractPrice: body.contractPrice,
      description: body.description,
      status: body.status || 'On-Going',
    })
    return this.fetch(`procurement/project/${id}/assign/vendor`, {
      body: reqBody,
      method: 'POST',
    })
  }

  static assignContract = (id, body = {}) => {
    const reqBody = this.cleanPayload({
      date: body.date,
      number: body.number,
    })
    return this.fetch(`procurement/project/${id}/assign/contract`, {
      body: reqBody,
      method: 'POST',
    })
  }

  static assignFile = (id, body = {}) => {
    const reqBody = this.cleanPayload(body)
    return this.fetch(`procurement/project/${id}/assign/file`, {
      body: reqBody,
      method: 'POST',
    })
  }

  static assignVendorHandler = (id, body = {}) => {
    const reqBody = this.cleanPayload(body)
    return this.fetch(`procurement/handler/${id}/assign/vendor`, {
      body: reqBody,
      method: 'POST',
    })
  }

  static assignPokjaPP = (id, body = {}) => {
    const reqBody = this.cleanPayload(body)
    return this.fetch(`procurement/procurement-officer/${id}/assign/division`, {
      body: reqBody,
      method: 'POST',
    })
  }

  static assignPengadaanHandler = (id, body = {}) => {
    const reqBody = this.cleanPayload({
      handlerID: body.handlerID,
    })
    return this.fetch(`procurement/project/${id}/assign/handler`, {
      body: reqBody,
      method: 'POST',
    })
  }

  // Upload File Requirement
  static uploadFileRequirement = (id, body = {}) => {
    const reqBody = this.cleanPayload({
      file: body.file,
    })
    return this.fetch(`procurement/project/${id}/upload`, {
      body: reqBody,
      method: 'POST',
    })
  }

  //   Handler
  static listHandler = (parameters = {}) => {
    const payload = this.cleanPayload({
      currentPage: parameters.currentPage || 1,
      pageSize: parameters.pageSize || 20,
      keyword: parameters.keyword,
      expand: parameters.expand || 'vendors',
      deleted: parameters.deleted || 0,
    })
    return this.fetch(`procurement/handler?${queryString.stringify(payload)}`)
  }

  static detailHandler = (id) => {
    const payload = this.cleanPayload({
      expand: 'vendors',
    })
    return this.fetch(`procurement/handler/${id}?${queryString.stringify(payload)}`)
  }

  static createHandler = (body = {}) => {
    const reqBody = this.cleanPayload({
      name: body.name,
      accountID: body.accountID,
      defaultDivisionID: body.defaultDivisionID,
    })
    return this.fetch('procurement/handler', { body: reqBody, method: 'POST' })
  }

  static updateHandler = (id, body = {}) => {
    const reqBody = this.cleanPayload({
      name: body.name,
      accountID: body.accountID,
      defaultDivisionID: body.defaultDivisionID,
    })
    return this.fetch(`procurement/handler/${id}`, {
      body: reqBody,
      method: 'POST',
    })
  }

  static archiveHandler = (id, body = {}) => {
    const reqBody = this.cleanPayload({
      deleted: body.deleted || 1,
    })

    return this.fetch(`procurement/handler/${id}/archive`, {
      body: reqBody,
      method: 'POST',
    })
  }
  //   Division
  static listDivision = (parameters = {}) => {
    const payload = this.cleanPayload({
      currentPage: parameters.currentPage || 1,
      pageSize: parameters.pageSize || 20,
      keyword: parameters.keyword,
      deleted: parameters.deleted,
      expand: parameters.expand,
    })
    return this.fetch(`procurement/division?${queryString.stringify(payload)}`)
  }

  static detailDivision = (id) => {
    const payload = this.cleanPayload({
      expand: 'procurementOfficers',
    })

    return this.fetch(`procurement/division/${id}?${queryString.stringify(payload)}`)
  }

  static createDivision = (body = {}) => {
    const reqBody = this.cleanPayload({
      name: body.name,
    })
    return this.fetch('procurement/division', {
      body: reqBody,
      method: 'POST',
    })
  }

  static updateDivision = (id, body = {}) => {
    const reqBody = this.cleanPayload({
      name: body.name,
    })
    return this.fetch(`procurement/division/${id}`, {
      body: reqBody,
      method: 'POST',
    })
  }

  static archiveDivision = (id, body = {}) => {
    const reqBody = this.cleanPayload({
      deleted: body.deleted,
    })

    return this.fetch(`procurement/division/${id}/archive`, {
      body: reqBody,
      method: 'POST',
    })
  }

  //   Vendor
  static listVendor = (parameters = {}) => {
    const payload = this.cleanPayload({
      currentPage: parameters.currentPage || 1,
      pageSize: parameters.pageSize || 20,
      keyword: parameters.keyword,
      isArchived: parameters.isArchived,
      expand: parameters.expand,
      order: parameters.order || 'desc',
      sort: parameters.sort || 'created',
    })
    return this.fetch(`procurement/vendor?${queryString.stringify(payload)}`)
  }

  static detailVendor = (id) => {
    const payload = this.cleanPayload({
      expand: 'handlers',
    })
    return this.fetch(`procurement/vendor/${id}?${queryString.stringify(payload)}`)
  }

  static createVendor = (body = {}) => {
    const reqBody = this.cleanPayload({
      name: body.name,
      NPWP: body.NPWP,
    })
    return this.fetch('procurement/vendor', {
      body: reqBody,
      method: 'POST',
    })
  }

  static updateVendor = (id, body = {}) => {
    const reqBody = this.cleanPayload({
      name: body.name,
      NPWP: body.NPWP,
    })
    return this.fetch(`procurement/vendor/${id}`, {
      body: reqBody,
      method: 'POST',
    })
  }

  static archiveVendor = (id) => {
    return this.fetch(`procurement/vendor/${id}/archive`, {
      method: 'POST',
    })
  }

  //   File Requirement
  static listFileRequirement = (parameters = {}) => {
    const payload = this.cleanPayload({
      currentPage: parameters.currentPage || 1,
      pageSize: parameters.pageSize || 20,
      keyword: parameters.keyword,
    })
    return this.fetch(`procurement/file-requirement?${queryString.stringify(payload)}`)
  }

  static detailFIlerequirement = (id) => {
    return this.fetch(`procurement/file-requirement/${id}`)
  }

  // List SPending
  static listSpending = (parameters = {}) => {
    const payload = this.cleanPayload({
      currentPage: parameters.currentPage || 1,
      pageSize: parameters.pageSize || 20,
      keyword: parameters.keyword,
    })
    return this.fetch(`procurement/expense-type?${queryString.stringify(payload)}`)
  }

  // PP or Procurement Officer
  static listProcurementOfficer = (parameters = {}) => {
    const payload = this.cleanPayload({
      expand: parameters.expand || 'divisions',
    })
    return this.fetch(`procurement/procurement-officer?${queryString.stringify(payload)}`)
  }

  static detailProcurementOfficer = (id, parameters = {}) => {
    const payload = this.cleanPayload({
      expand: parameters.expand || 'divisions',
    })
    return this.fetch(`procurement/procurement-officer/${id}?${queryString.stringify(payload)}`)
  }

  // Login
  static login = (username, password) => {
    return this.fetch(`access/login`, {
      body: { username, password },
      method: 'POST',
    })
  }

  static fetch = async (uri, config) => {
    const defaultConfig = {
      headers: {
        Role: 'Procurement-Officer',
        Accept: 'application/json',
      },
    }

    const token = await getToken()

    if (token) {
      defaultConfig.headers['Authorization'] = `Bearer ${token}`
    }

    const mergeConfig = { ...defaultConfig, ...config }
    let bodyConfig = {}
    if (mergeConfig.body && !(mergeConfig.body instanceof FormData)) {
      const form = new FormData()
      Object.entries(mergeConfig.body).map(([label, value]) => {
        if (Array.isArray(value)) {
          value.map((each, index) => {
            if (typeof each === 'object') {
              Object.keys(each).map((key) => {
                form.append(`${label}[${index}][${key}]`, each[key])
                return true
              })
            } else {
              form.append(`${label}[]`, each)
            }
            return true
          })
        } else {
          form.append(label, value)
        }

        return true
      })
      bodyConfig = { body: form }
    }
    const cleanConfig = { ...mergeConfig, ...bodyConfig }
    const url = `${BASE_URL}/${uri}`
    return fetch(url, cleanConfig)
      .then((res) => {
        if (res.status !== 200) {
          if (this.component) {
            let { errorAPI } = this.component.state
            if (!errorAPI) {
              errorAPI = new Map()
            }
            errorAPI.set(uri, res.statusText)
            this.component.setState({ errorAPI: errorAPI })
          }
        }
        return res.json()
      })
      .catch((err) => {
        console.log('error', err)
      })
  }

  static cleanPayload = (payload) => {
    Object.keys(payload).forEach((key) => payload[key] == null && delete payload[key])
    return payload
  }
}
