import { useMemo, useState } from "react";
// import { ReactComponent as Logo } from "../../Assets/logo/logo.svg";
import { ReactComponent as Logo } from "../../Assets/logo/mitrasdudi-logo.svg";
import { ReactComponent as IconUser } from "../../Assets/icons/user-login.svg";
import { ReactComponent as IconLock } from "../../Assets/icons/lock.svg";
import { ReactComponent as IconCalendar } from "../../Assets/icons/calendar.svg";
import { Link } from "react-router-dom";
import { isLoggedIn, login, logout } from "../../Services/Authentication";
import BeatLoader from "react-spinners/BeatLoader";
import useInput from "../../Hooks/useInput";
import { spreadYear } from "../../Functions/Spread";

const Login = ({ history }) => {
  const defaultYear = (new Date().getFullYear());
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [input, setInput] = useInput({ username: null, password: null, year: defaultYear.toString() })

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState({ errors: [] });
  const years = spreadYear(2022, defaultYear)

  const submit = (event) => {
    event.preventDefault();

    setIsSubmiting(true);

    if (input.username && input.password) {
      login(input.username, input.password, input.year).then((response) => {
        console.log("Res Login", response);
        if (response.code === 200) {
          setIsSubmiting(false);
          history.push("/home");
        } else {
          setMessage(response.message);
          setIsSubmiting(false);
        }
      });
    } else {
      setIsSubmiting(false);
      setMessage("Username or password cannot be blank!");
    }
  };

  return (
    <div className="login">
      <section className="login__left">
        <div className="login__left__title">
          <h1>
            MITRASDUDI <br />
            PROCUREMENT
          </h1>
          <p>
            Sistem Registrasi untuk Disposisi <br />
            Pengadaan
          </p>
        </div>
      </section>
      <section className="login__right">
        <form className="login__right__form" onSubmit={submit}>
          <Logo />
          <h2>SELAMAT DATANG {input.username}!</h2>
          <div>
            <IconUser />
            <input
              type="text"
              placeholder="USERNAME"
              required
              defaultValue={input.username}
              onChange={({ target: { value } }) => setInput('username', value)}
            />
          </div>
          <div>
            <IconLock />
            <input
              type="password"
              placeholder="PASSWORD"
              required
              defaultValue={input.password}
              onChange={({ target: { value } }) => setInput('password', value)}
            />
          </div>
          <div>
            <IconCalendar />
            <select defaultValue={(defaultYear).toString()} onChange={({ target: { value } }) => setInput('year', value)}>
              {years.map(year => <option value={year}>{year}</option>)}
            </select>
          </div>
          {message === "" ? (
            <center>
              {errorMessage.errors.map((message) => {
                return <p>{message}</p>;
              })}
            </center>
          ) : (
            <center>
              <p>{message}</p>
            </center>
          )}
          <button type="submit">
            {isSubmiting ? <BeatLoader size={7} color="#fff" /> : "LOGIN"}
          </button>
        </form>
        <div className="login__right__footer">
          <p>©{new Date().getFullYear()} by mitrasdudi.id</p>
          <label>Kementerian Pendidikan Dan Kebudayaan</label>
        </div>
      </section>
    </div>
  );
};

export default Login;
