import { ReactComponent as Logo } from "../../Assets/logo/mitrasdudi-logo-small.svg";
import { ReactComponent as IconEmail } from "../../Assets/icons/mail-red.svg";

import ImageHeader from "../../Assets/images/header-illustration.png";
import ImageAbout from "../../Assets/images/about-illustration.png";
import IconArrowTop from "../../Assets/icons/arrow-top-white.png";
import { Link } from "react-router-dom";

const Landing = () => {
  return (
    <div className="landing">
      {/* home */}
      <div className="landing__home" id="#">
        {/* menu */}
        <div className="landing__home__menu">
          <div className="landing__home__menu__logo">
            <Logo />
            <span>Procurement</span>
          </div>
          <div className="landing__home__menu__item">
            <a href="#about">About</a>
            <a href="#contact">Contact</a>
            <Link to="/login">
              <button type="button">Login</button>
            </Link>
          </div>
        </div>

        {/* Header */}
        <div className="landing__home__header">
          <section>
            <h3>Welcome to</h3>
            <h1>Procurement !</h1>
            <div />
            <p>
              Selamat datang di aplikasi Mitrasdudi Procurement. Login untuk
              menggunakan banyak fitur di aplikasi ini !
            </p>
            <Link to="/login">
              <button type="button">Login</button>
            </Link>
          </section>
          <img src={ImageHeader} alt="" />
        </div>
      </div>

      {/* about */}
      <div className="landing__about" id="about">
        <img src={ImageAbout} alt="" />
        <section>
          <h1>Tentang Kami</h1>
          <div />
          <p>
            {/* Mitrasdudi Procurement Bertujuan memudahkan administrator Pengadaan
            untuk mengkompilasi permohonan pengadaan dan meneruskan permohonan
            pengadaan kepada layanan pengadaan Bagian Umum untuk kemudian
            diproses dan dijadikan Dokumen Perjanjian antara end-user dengan
            Calon Penyedia. Selain itu, juga memudahkan rekanan untuk melakukan
            tracking Dokumen miliknya. */}
            Laman ini bertujuan memudahkan konsolidasi data arsip pengadaan dan
            manajemen pengadaan yang terdesentralisasi di masing-masing KJF.
          </p>
        </section>
      </div>

      {/* footer */}
      <div className="landing__footer" id="contact">
        <section className="landing__footer__infoOne">
          <div>
            <Logo />
            <h2>Procurement</h2>
          </div>
          <p>
            Silahkan kontak ke email kami jika ada yang di tanyakan tentang web
            kami.
          </p>
          <section>
            <section>
              <IconEmail />
            </section>
            <span>securityadmin@serundeng.info</span>
          </section>
          <label>©{new Date().getFullYear()} by mitrasdudi.id</label>
        </section>
        <section className="landing__footer__infoTwo">
          <h2>Alamat Kami</h2>
          <p>
            {/* Bagian Umum, */}
            <br /> Gedung D Lt. 17-18, Komplek Kemdikbud
            <br /> Jln. Jenderal Sudirman Senayan
            <br /> Jakarta Pusat
            {/* <br /> Contact */}
          </p>
        </section>
        {/* <section className="landing__footer__infoThree">
          <p>Apa yang anda pikirkan tentang sistem ini?</p>
          <button>Beri Saran & Masukkan</button>
        </section> */}
      </div>

      <a className="landing__buttonUp" href="#">
        <img src={IconArrowTop} alt="" />
      </a>
    </div>
  );
};

export default Landing;
