import { useState } from "react";

import API from "../../Services/API";

import Layout from "../../Layout";
import BeatLoader from "react-spinners/BeatLoader";

const Create = ({ history }) => {
  const [data, setData] = useState({
    name: null,
    NPWP: null,
  });

  const [errorData, setErrorData] = useState({
    name: null,
    NPWP: null,
  });

  const [isSubmiting, setIsSubmiting] = useState(false);

  const submit = (event) => {
    event.preventDefault();

    setIsSubmiting(true);

    API.createVendor(data).then((response) => {
      if (response.code === 200) {
        history.push("/rekanan");
      } else {
        setErrorData((prevState) => ({
          ...prevState,
          name: response.errors.name[0],
          NPWP: response.errors.NPWP[0],
        }));
      }
      setIsSubmiting(false);
    });
  };

  return (
    <Layout>
      <div className="formRekanan">
        <h2>Buat Rekanan Baru</h2>
        <form onSubmit={submit}>
          <section>
            <h2>No. NPWP</h2>
            <section>
              <div>
                <input
                  type="text"
                  placeholder="NPWP"
                  required
                  required
                  value={data.NPWP}
                  onChange={({ target: { value } }) =>
                    setData((prevState) => ({ ...prevState, NPWP: value }))
                  }
                />
              </div>
              {/* <button type="button">Check</button> */}
            </section>
          </section>
          <section>
            <h2>Nama Rekanan</h2>
            <div>
              <input
                type="text"
                placeholder="Nama Rekanan"
                required
                value={data.name}
                onChange={({ target: { value } }) =>
                  setData((prevState) => ({ ...prevState, name: value }))
                }
              />
            </div>
            <p
              style={{
                marginTop: 16,
                textAlign: "center",
                fontSize: 11,
                color: "red",
              }}
            >
              {errorData.name}
              {errorData.NPWP}
            </p>
          </section>
          {/* <section>
            <h2>Alamat</h2>
            <div>
              <textarea rows="10" placeholder="Alamat"></textarea>
            </div>
          </section>

          <section>
            <h2>Operator</h2>
            <div>
              <select>
                <option>-- Pilih Operator --</option>
                <option>Administrator</option>
              </select>
            </div>
          </section> */}
          <div>
            <button type="submit">
              {isSubmiting ? <BeatLoader size={6} color="#2962FF" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Create;
