import "./Styles/main.scss";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Routes from "./routes";
import AuthContextProvider from "./Context/AuthContext";

const App = () => {
  return (
    <AuthContextProvider>
      <Router>
        <Switch>
          <Routes />
        </Switch>
      </Router>
    </AuthContextProvider>
  );
};

export default App;
